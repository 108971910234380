/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.topbar {
  position: relative;
  border-bottom: 1px solid #e0e0e0;
  user-select: none;
}
.topbar .topbar-dropmenu {
  z-index: 9999;
  overflow: hidden;
  position: relative;
  max-height: 0;
  width: 100%;
  background: url(../../../assets/images/topbar-bg.jpg) repeat -60px top;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4) inset;
  transition: 0.4s max-height ease;
}
.topbar .topbar-dropmenu:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}
.topbar .topbar-dropmenu .ant-row {
  padding: 17px 20px 10px;
}
.topbar .topbar-dropmenu .ant-row > div .metro-tile {
  display: block;
  color: #FFF;
  height: 95px;
  padding: 15px 5px 0;
  margin-bottom: 8px;
  border-radius: 4px;
  text-align: center;
  background: rgba(255, 255, 255, 0.25);
  transition: background 0.2s ease;
  position: relative;
}
.topbar .topbar-dropmenu .ant-row > div .metro-tile > i {
  font-size: 44px;
}
.topbar .topbar-dropmenu .ant-row > div .metro-tile > .metro-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 11px;
  font-weight: 600;
}
.topbar .topbar-dropmenu .ant-row > div .metro-tile:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.4);
}
.topbar.topbar-expand .topbar-dropmenu {
  max-height: 1000px;
}
.topbar .topbar-content {
  position: relative;
  min-height: 48px;
  padding: 12px 21px;
  background: #fafafa;
}
.topbar .topbar-content .ant-breadcrumb {
  display: inline-block;
  font-size: 12px;
}
.topbar .topbar-content .ant-breadcrumb .first {
  color: #555;
  font-size: 18px;
}
.topbar .topbar-content .ant-breadcrumb .icon {
  position: relative;
  font-size: 15px;
  color: #777;
  top: -1px;
}
.topbar .topbar-content .ant-breadcrumb a {
  color: #1890ff;
}
.topbar .topbar-content .ant-breadcrumb a:hover {
  color: #8cc8ff;
}
.topbar .topbar-content .topbar-right {
  float: right;
  font-size: 20px;
  color: #1890ff;
}
.topbar .topbar-content .topbar-right:hover {
  color: #40a9ff;
}
.topbar .topbar-content .topbar-right i {
  transform: scale(-1, 1);
  transition: 0.3s transform ease 0.1s;
}
.topbar .topbar-content .topbar-right.collapse i {
  transform: scale(1, 1);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@value NORMAL: #6EEF95;
@value ALARM: #EF4A4A;
@value LOSE: #A86EEF;
@value OFFLINE: #BEBEBE;
@value NOTACTIVE: #9ECCFF;
@value ARM: #6EEF95;
@value DISARM: #EF4A4A;
@value ENABLE: #6EEF95;
@value DISABLE: #EF4A4A;
@value HANDLE: #6EEF95;
@value UN_HANDLE: #EF4A4A;
@value READ: #6EEF95;
@value UN_READ: #EF4A4A;
@value BLUE: #03B4FF;
@value SMOKECONCENTRATION: #2FC25B;
@value TEMPERATURE: #2FC25B;
@value SNR: #2FC25B;
@value SIGNALSTRENGTH: #2FC25B;
@value BATTERYLEVEL: #2FC25B;
@value SMOKEBATTERY: #2FC25B;
@value LEAKAGEVALUE: #F57C00;
@value LEAKAGE1VALUE: #F57C00;
@value LEAKAGE2VALUE: #1976D2;
@value CURRENTAVALUE: #FBC02D;
@value CURRENTBVALUE: #388E3C;
@value CURRENTCVALUE: #D32F2F;
@value VOLTAGEAVALUE: #FBC02D;
@value VOLTAGEBVALUE: #388E3C;
@value VOLTAGECVALUE: #D32F2F;
@value TEMP1VALUE: #FBC02D;
@value TEMP2VALUE: #388E3C;
@value TEMP3VALUE: #D32F2F;
@value TEMP4VALUE: #5D4037;
@value DEVICETEMPVALUE: #00796B;
@value POWERFACTORA: #FBC02D;
@value POWERFACTORB: #388E3C;
@value POWERFACTORC: #D32F2F;
@value ACTIVEPOWERA: #FBC02D;
@value ACTIVEPOWERB: #388E3C;
@value ACTIVEPOWERC: #D32F2F;
@value KWAVALUE: #FBC02D;
@value KWBVALUE: #388E3C;
@value KWCVALUE: #D32F2F;
@value BATTERY: #2FC25B;
@value SIGNAL: #2FC25B;
@value RSSI: #2FC25B;
@value FINISH: #00DE73;
@value UN_FINISH: #BEBEBE;
@value SUBMIT: #FFBB37;
@value HANDLE: #00AF4C;
@value REJECT: #EF4A4A;
@value GREEN: #00DE73;
@value RED: #EF4A4A;
@value WHITE: #FFFFFF;
@value YELLOW: #FFFF00;
@value ORANGE: #FFA500;
@value UNIT: #0CD9FF;
@value ORGANS: #FFBB37;
.lineTest {
  border: 1px solid #1976D2;
}
.vCenter {
  display: flex;
  align-items: center;
}
.hCenter {
  display: flex;
  justify-content: center;
}
.textHide {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.unitGroup {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.unitGroup .body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 8px;
}
.unitGroup .body .groupItem {
  width: 300px;
  height: 25%;
  min-height: 170px;
}
.unitGroup .body .groupItem .groupBody {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.unitGroup .body .groupItem .itemTitle {
  width: 100%;
  height: 80px;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}
.unitGroup .body .groupItem .itemTitle .image {
  margin-left: 20px;
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.unitGroup .body .groupItem .itemTitle .info {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}
.unitGroup .body .groupItem .itemTitle .info .text {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #f5a50b;
}
.unitGroup .body .groupItem .itemTitle .info .text1 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  color: rgba(150, 150, 150, 0.85);
}
.unitGroup .body .groupItem .itemBody {
  width: 100%;
  height: 1px;
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.unitGroup .body .groupItem .itemBody .user .name,
.unitGroup .body .groupItem .itemBody .device .name {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.3;
}
.unitGroup .body .groupItem .itemBody .user .count,
.unitGroup .body .groupItem .itemBody .device .count {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 600;
}
.unitGroup .body .groupItem .itemBody .device {
  margin-left: 74px;
}
.unitGroup .body .groupItem .itemBody .user {
  margin-left: 49px;
}
.unitGroup .body .addGroupItem .groupBody {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.unitGroup .body .addGroupItem .image {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.unitGroup .body .addGroupItem .text {
  margin-left: 9px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
}
.image {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antui-panel {
  position: relative;
  margin-bottom: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  border-radius: 2px;
}
.antui-panel-header {
  flex: none;
  position: relative;
  height: 40px;
  line-height: 36px;
  background: #fafafa;
  color: #666666;
  font-size: 13px;
  font-weight: 600;
  padding: 0 8px;
  border: 1px solid #e5e5e5;
  border-bottom: 0;
  overflow: hidden;
}
.antui-panel-header-title {
  padding-left: 6px;
  float: left;
}
.antui-panel-header-controls {
  float: right;
  display: block;
  cursor: pointer;
}
.antui-panel-header-controls > a {
  padding-top: 1px;
  display: inline-block;
  opacity: 0.7;
  width: 30px;
  color: #999;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
}
.antui-panel-header-controls > a:hover {
  opacity: 1;
  color: #1890ff;
}
.antui-panel-body {
  flex: auto;
  display: flex;
  position: relative;
  border: 1px solid #e5e5e5;
  transition: 0.2s ease-out;
  overflow: hidden;
}
.antui-panel-body .panel-content {
  padding: 15px;
  width: 100%;
  flex: auto;
}
.antui-panel.panel-fullscreen {
  z-index: 999 !important;
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
}
.antui-panel.panel-collapsed .antui-panel-body {
  max-height: 10px;
}
.antui-panel.cover .panel-content {
  padding: 0;
}

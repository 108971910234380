/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.screen-lock-page {
  position: fixed !important;
  z-index: 9998;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-top: 70px;
  background: radial-gradient(ellipse at center, #1f3649 0%, #17253d 44%, #040d11 100%);
}
.screen-lock-page .container {
  width: 80%;
  margin: auto;
}
.screen-lock-page .container .pattern-logo {
  font-size: 22px;
  color: #ddd;
}
.screen-lock-page .container .pattern-logo img {
  width: 45px;
  margin-right: 18px;
}
.screen-lock-page .container .pattern-logo b {
  color: #aaa;
}
.screen-lock-page .container .pattern-logo > * {
  vertical-align: middle;
}
.screen-lock-page .container .patter-container {
  text-align: center;
}
.screen-lock-page .container .patter-container .patter-title {
  color: #fff;
  margin-bottom: 20px;
  letter-spacing: 0.3px;
  font-size: 20px;
}
.screen-lock-page .container .patter-container .patt-holder {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 14px;
  margin: 0 auto 40px;
}
.screen-lock-page .container .patter-container > p {
  color: #E0E0E0;
  width: 310px;
  margin: 13px auto;
}
.screen-lock-page .container .patter-tip {
  text-align: center;
}
.screen-lock-page .antui-clock {
  bottom: 50px;
  color: #fff;
  padding: 0;
  position: absolute;
  right: 50px;
  width: auto;
}
.pattern-tip-modal {
  margin: 50px auto;
  text-align: center;
}
.coming-soon-page {
  background-color: #f0f0f0;
}
.coming-soon-page .title {
  font-size: 26px;
  font-weight: 300;
  text-align: center;
  color: #333;
  line-height: 28px;
  margin: 25px 10px 55px 10px;
}
.coming-soon-page .place_balk_workers {
  width: 437px;
  height: 213px;
  position: relative;
  margin: 90px auto -10px;
}
.coming-soon-page .place_balk_workers .balk_workers {
  width: 437px;
  height: 213px;
  position: relative;
  background-image: url('./images/workers.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-color: transparent;
  background-size: 100%;
  z-index: 3;
}
.coming-soon-page .place_balk_workers .balk {
  width: 60px;
  height: 23px;
  background-image: url('./images/balk.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-color: transparent;
  background-size: 100%;
  position: absolute;
  top: 90px;
  left: 178px;
}
.coming-soon-page .place_balk_workers .saw {
  width: 325px;
  height: 63px;
  background-image: url('./images/saw.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-color: transparent;
  background-size: 100%;
  position: absolute;
  left: 53px;
  top: 68px;
  z-index: 2;
  animation: 1s ease 0s normal none infinite saw;
  animation: saw 1s infinite ease-in-out;
  transform-origin: 85% 65%;
}
.coming-soon-page #watch {
  color: #f6f7f9;
  text-align: center;
}
.coming-soon-page #watch .dash {
  display: inline-block;
  padding: 20px 30px;
  line-height: 72px;
  text-align: center;
  margin: 0 -3px;
}
.coming-soon-page #watch .dash:first-child {
  border-radius: 8px 0px 0px 8px;
  background-color: #0089e9;
}
.coming-soon-page #watch .dash:last-child {
  border-radius: 0px 8px 8px 0px;
  background-color: #00b6f9;
  padding-left: 28px;
  padding-right: 28px;
}
.coming-soon-page #watch .dash:nth-child(2) {
  background-color: #0097f6;
}
.coming-soon-page #watch .dash:nth-child(3) {
  background-color: #00a7f8;
}
.coming-soon-page #watch .dash .digit {
  display: block;
  width: 48px;
  font-size: 44px;
  line-height: 44px;
  height: 38px;
  float: left;
}
.coming-soon-page #watch .dash .digit .top,
.coming-soon-page #watch .dash .digit .bottom {
  font-weight: 300;
}
.coming-soon-page #watch .dash span.dash_title {
  font-size: 14px;
  line-height: 16px;
  padding-top: 6px;
  font-weight: 300;
  display: block;
  text-align: center;
  clear: both;
}
@keyframes saw {
  0% {
    left: 53px;
  }
  50% {
    left: 72px;
  }
  100% {
    left: 53px;
  }
}
.page403 {
  background: #fff;
}
.page403 .ant-layout-content {
  overflow: hidden !important;
}
.page403 .error-block {
  margin: 50px;
}
.page403 .center-block {
  margin: 0 100px;
}
.page403 .center-block .error-title {
  font-size: 60px;
  font-weight: 800;
  color: #444;
  margin-bottom: 10px;
}
.page403 .center-block .error-subtitle {
  font-weight: 400;
  font-size: 32px;
  color: #444;
  margin-bottom: 80px;
}
.page404 .ant-layout-content {
  overflow: hidden !important;
}
.page404 .me404 {
  width: 1000px;
  height: 480px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -400px;
  margin-top: -240px;
}
.page404 .st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #e8ebed;
}
.page404 .st1 {
  fill: #ffffff;
  stroke: #89949b;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.page404 .st2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #dbdfe1;
}
.page404 .st3 {
  fill: #ffffff;
}
.page404 .st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #e8ebed;
  stroke: #89949b;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.page404 .st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
  stroke: #89949b;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.page404 .st6 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
  stroke: #89949b;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.page404 .st7 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
  stroke: #89949b;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.page404 .st8 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
  stroke: #89949b;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.page404 .st9 {
  fill: #89949b;
}
.page404 .st10 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #89949b;
}
.page404 .st11 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
  stroke: #89949b;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.page404 .st12 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
.page404 .st13 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
  stroke: #8894a0;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.page404 .st14 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
  stroke: #89949b;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.page404 .st15 {
  fill: none;
  stroke: #89949b;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.page404 #monkey-eye-l {
  transform-origin: 191px 257px;
  animation: blink-l 12s infinite ease-in-out;
}
.page404 #monkey-eye-r {
  transform-origin: 205px 256px;
  animation: blink-r 12s infinite ease-in-out;
}
@keyframes blink-l {
  0% {
    transform: rotateX(0deg);
  }
  2% {
    transform: rotateX(80deg);
  }
  4%,
  20% {
    transform: rotateX(0deg);
  }
  22% {
    transform: rotateX(80deg);
  }
  24%,
  30% {
    transform: rotateX(0deg);
  }
  32% {
    transform: rotateX(80deg);
  }
  34%,
  70% {
    transform: rotateX(0deg);
  }
  72% {
    transform: rotateX(80deg);
  }
  74%,
  100% {
    transform: rotateX(0deg);
  }
}
@keyframes blink-r {
  0% {
    transform: rotateX(0deg);
  }
  2% {
    transform: rotateX(80deg);
  }
  4%,
  30% {
    transform: rotateX(0deg);
  }
  32% {
    transform: rotateX(80deg);
  }
  34%,
  50% {
    transform: rotateX(0deg);
  }
  52% {
    transform: rotateX(80deg);
  }
  54%,
  100% {
    transform: rotateX(0deg);
  }
}
.page500 {
  background: #fff;
}
.page500 .ant-layout-content {
  overflow: hidden !important;
}
.page500 .error-block {
  margin: 50px;
}
.page500 .center-block {
  margin: 0 100px;
}
.page500 .center-block .error-title {
  font-size: 60px;
  font-weight: 800;
  color: #444;
  margin-bottom: 10px;
}
.page500 .center-block .error-subtitle {
  font-weight: 400;
  font-size: 32px;
  color: #444;
  margin-bottom: 80px;
}
.result-fragment {
  background: url('../../../assets/images/bg.jpg');
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.result-fragment .center-block {
  margin: 70px auto;
  max-width: 625px;
}
.result-fragment .center-block .result-header .title {
  font-size: 24px;
  margin-top: 19px;
  font-weight: 600;
  line-height: 1.1;
}
.result-fragment .center-block .result-header .title i {
  font-size: 32px;
  width: 46px;
  text-align: center;
  background: #eee;
  border: 1px solid #DDD;
  border-radius: 50%;
  padding: 6px;
  margin-right: 9px;
  position: relative;
}
.result-fragment .center-block .result-header .title.success i {
  color: #52c41a;
}
.result-fragment .center-block .result-header .title.error i {
  color: #f5222d;
}
.result-fragment .center-block .result-header .title.warning i {
  color: #faad14;
}
.result-fragment .center-block .result-header .title.info i {
  color: #1890ff;
}
.result-fragment .center-block .result-header .extra {
  color: #aaa;
  text-align: right;
  padding-right: 10px;
}
.result-fragment .center-block .result-header .description {
  margin-top: 10px;
}
.result-fragment .center-block .result-body {
  position: relative;
  margin: 20px 0;
  background-color: #fff;
  border-radius: 4px;
  padding: 25px;
  border: 1px solid #e5e5e5;
  color: #999;
  font-size: 15px;
  line-height: 1.8;
}
.result-fragment .center-block .result-body .action-btns {
  margin-top: 20px;
  text-align: right;
}
.result-fragment .center-block .result-body .action-btns > button,
.result-fragment .center-block .result-body .action-btns > .ant-btn {
  margin-left: 8px;
}
.result-fragment .center-block .result-footer {
  color: #999;
  font-size: 13px;
  text-align: center;
}
.result-fragment .center-block .result-footer a {
  color: #1890ff;
  font-weight: 600;
}
.result-fragment .center-block .result-footer a:hover {
  color: #40a9ff;
}
.backhome {
  position: absolute;
  border: 1px solid #89949b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 0px;
  color: #89949b;
  top: 20px;
  font-size: 48px;
  left: 20px;
  overflow: hidden;
}
.backhome:hover {
  color: #1890ff;
  border-color: #1890ff;
  background: #fff;
}

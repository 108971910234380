/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@value NORMAL: #6EEF95;
@value ALARM: #EF4A4A;
@value LOSE: #A86EEF;
@value OFFLINE: #BEBEBE;
@value NOTACTIVE: #9ECCFF;
@value ARM: #6EEF95;
@value DISARM: #EF4A4A;
@value ENABLE: #6EEF95;
@value DISABLE: #EF4A4A;
@value HANDLE: #6EEF95;
@value UN_HANDLE: #EF4A4A;
@value READ: #6EEF95;
@value UN_READ: #EF4A4A;
@value BLUE: #03B4FF;
@value SMOKECONCENTRATION: #2FC25B;
@value TEMPERATURE: #2FC25B;
@value SNR: #2FC25B;
@value SIGNALSTRENGTH: #2FC25B;
@value BATTERYLEVEL: #2FC25B;
@value SMOKEBATTERY: #2FC25B;
@value LEAKAGEVALUE: #F57C00;
@value LEAKAGE1VALUE: #F57C00;
@value LEAKAGE2VALUE: #1976D2;
@value CURRENTAVALUE: #FBC02D;
@value CURRENTBVALUE: #388E3C;
@value CURRENTCVALUE: #D32F2F;
@value VOLTAGEAVALUE: #FBC02D;
@value VOLTAGEBVALUE: #388E3C;
@value VOLTAGECVALUE: #D32F2F;
@value TEMP1VALUE: #FBC02D;
@value TEMP2VALUE: #388E3C;
@value TEMP3VALUE: #D32F2F;
@value TEMP4VALUE: #5D4037;
@value DEVICETEMPVALUE: #00796B;
@value POWERFACTORA: #FBC02D;
@value POWERFACTORB: #388E3C;
@value POWERFACTORC: #D32F2F;
@value ACTIVEPOWERA: #FBC02D;
@value ACTIVEPOWERB: #388E3C;
@value ACTIVEPOWERC: #D32F2F;
@value KWAVALUE: #FBC02D;
@value KWBVALUE: #388E3C;
@value KWCVALUE: #D32F2F;
@value BATTERY: #2FC25B;
@value SIGNAL: #2FC25B;
@value RSSI: #2FC25B;
@value FINISH: #00DE73;
@value UN_FINISH: #BEBEBE;
@value SUBMIT: #FFBB37;
@value HANDLE: #00AF4C;
@value REJECT: #EF4A4A;
@value GREEN: #00DE73;
@value RED: #EF4A4A;
@value WHITE: #FFFFFF;
@value YELLOW: #FFFF00;
@value ORANGE: #FFA500;
@value UNIT: #0CD9FF;
@value ORGANS: #FFBB37;
.lineTest {
  border: 1px solid #1976D2;
}
.vCenter {
  display: flex;
  align-items: center;
}
.hCenter {
  display: flex;
  justify-content: center;
}
.textHide {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.userModal {
  position: relative;
}
.userClass {
  padding: 0;
}
.userClass .button {
  width: 100%;
}
.userModalDiv {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
}
.userModalDiv .searchTitle {
  width: 100%;
  height: auto;
  margin-bottom: 11px;
}
.unUserBody {
  width: 100%;
  height: 1px;
  flex-grow: 1;
}
.unUserBody .checkboxGroup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.unUserBody .userItem {
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  cursor: pointer;
  flex-shrink: 0;
}
.unUserBody .userItem:last-child {
  margin-bottom: 2px;
}
.unUserBody .userItem .title {
  width: 100%;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  background: rgba(80, 84, 95, 0.25);
  padding: 0 9px;
}
.unUserBody .userItem .body {
  width: 100%;
  height: 1px;
  flex-grow: 1;
  padding: 0 10px;
  display: flex;
  align-items: center;
}
.unUserBody .userItem .body .image {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.unUserBody .userItem .body .info {
  width: 1px;
  height: auto;
  object-fit: contain;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.unUserBody .userItem .body .info .text1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000;
}
.unUserBody .userItem .body .info .text2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
}

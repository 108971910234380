.main {
  display: flex;
  height: 70vh;
  flex-direction: column;
  padding-bottom: 8px;
  & .search {
    margin-bottom: 8px;
  }
  & .tree {
    height: 1px;
    flex-grow: 1;
    overflow-y: auto;
  }
}


/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antui-clock > .date {
  font-size: 20px;
  letter-spacing: 0.3px;
  text-align: right;
}
.antui-clock > ul {
  text-align: right;
  list-style: none;
  margin: 0;
  padding: 0;
}
.antui-clock > ul > li {
  display: inline;
  font-family: Roboto;
  font-size: 60px;
  font-weight: 900;
}
.antui-clock > ul > li.point {
  padding: 0 4px;
  animation: 1s ease 0s normal none infinite flash;
  position: relative;
  top: -5px;
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.card_tab :global .ant-tabs-nav .ant-tabs-tab {
  display: inline-block;
  height: 100%;
  margin: 0 5px 0 0;
  padding: 12px 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  text-decoration: none;
}
:global .ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #d9d9d9;
  width: 80px !important;
  height: 80px !important;
  border-radius: 4px;
  background-color: #fafafa;
  text-align: center;
  cursor: pointer;
  -webkit-transition: border-color 0.3s ease;
  -o-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
  vertical-align: top;
  margin-right: 8px;
  margin-bottom: 8px;
  display: table;
}
.layout {
  padding: 5px 0px;
  background-color: #fff;
  display: flex;
  width: 100%;
  min-height: calc(100vh - 183px);
}
.layout .content_left {
  padding: 10px 20px;
  text-align: center;
}
.layout .content_right {
  flex: 1;
}
.content_layout {
  min-height: calc(100vh - 208px);
  box-sizing: border-box;
  overflow-x: hidden;
  margin: -24px 10px 10px 10px;
  background: #fff;
}
.btn_green {
  background-color: #339966;
  color: #fff;
}
.btn_red {
  background-color: #f04864;
  color: #fff;
}
.btn_orange {
  background-color: #ff6600;
  color: #fff;
}
.btn_pink_light {
  background-color: #7b7bde;
  color: #fff;
}
.btn_black_light {
  background-color: #5a5a5a;
  color: #fff;
}
.btn_pink {
  background-color: #5a5ade;
  color: #fff;
}
.type_item {
  width: 160px;
  height: 80px;
  box-sizing: border-box;
  padding: 5px 15px;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  color: #fff;
  box-shadow: 2px 2px 3px 2px #c6c6c6;
}
.type_item .item_left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
}
.type_item .item_right {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.type_item .item_right .type_name {
  width: 100%;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  justify-content: center;
  text-overflow: ellipsis;
}
.detail_item {
  display: flex;
  width: 100%;
  padding: 5px 2px;
}
.detail_item .item_label {
  width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.detail_item .item_value {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.address_box {
  display: flex;
  width: 100%;
  height: 100%;
}
.tab_content {
  border-radius: 5px;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0px;
  width: 545px;
  background-color: #fff;
}
.tab_content :global .ant-tabs-content {
  margin-top: -10px;
  height: calc(100% - 60px);
  overflow-x: hidden;
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.tab_content :global .ant-tabs-content::-webkit-scrollbar {
  width: 0 !important;
}
.tab_content .tab_panel {
  width: 100%;
}
.tab_content .tab_panel .time_item {
  display: flex;
}
.tab_content .tab_panel .time_item .item_label {
  flex: 1;
}
.tab_content .tab_panel .time_item .item_extra {
  width: 50px;
}
.info_item {
  display: flex;
  padding: 5px 10px;
}
.info_item .item_label {
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.info_item .item_value {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.base_list {
  display: flex;
}
.base_list .list_left {
  flex: 1;
  overflow: hidden;
}
.base_list .list_left .info_item {
  display: flex;
  padding: 5px 10px;
}
.base_list .list_left .info_item .item_label {
  width: 75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.base_list .list_left .info_item .item_value {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.base_list .list_right {
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}
.param_list {
  width: 100%;
}
.param_list .param_item {
  width: 100%;
  height: 30px;
}
.param_list .param_item .param_label {
  margin-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.param_list .param_item .param_value {
  margin-right: 10px;
  margin-top: -20px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}
.base_param_list {
  width: 100%;
}
.base_param_list .info_item {
  display: flex;
  padding: 5px 10px;
}
.base_param_list .info_item .item_label {
  width: 65px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.base_param_list .info_item .item_value {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.no_data {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.no_data img {
  width: 40px;
  height: 40px;
}
.no_data p {
  color: #bfbfbf;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pagination-item-active {
  background-color: #EC6700;
  border-color: #d9d9d9;
  color: #fff;
}
.ant-pagination-item a :hover,
.ant-pagination-item a :focus {
  border-color: #d9d9d9;
  color: #fff;
}
.ant-pagination-item-active a {
  color: #fff;
}
.ant-pagination-item-active a:hover,
.ant-pagination-item-active a:focus {
  background-color: #EC9900;
  border-color: #d9d9d9;
  color: #fff;
}
.antui-datatable.table-row-alternate-color table {
  background: #fff;
  width: 100%;
}
.antui-datatable.table-row-alternate-color table tr:nth-child(odd) {
  background: #f6f6f6;
}
.antui-datatable.table-row-alternate-color table tr:nth-child(even) {
  background: #fff;
}
.antui-datatable.table-row-alternate-color table tr:hover {
  background: rgba(45, 183, 245, 0.2);
}
.antui-datatable .ant-table-small .ant-table-tbody > tr > td {
  padding: 3px 8px;
  height: 35px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.antui-datatable .ant-table-small .ant-table-thead > tr > th {
  font-weight: 700;
  color: #666;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.antui-datatable .ant-table-footer {
  padding: 8px;
}
.antui-datatable .ant-table-tbody > tr.ant-table-row-selected td {
  background: #fffde7;
}
.antui-datatable .ant-table-tbody > tr.disabled > td {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
}
.antui-datatable .table-row-button button,
.antui-datatable .table-row-button a {
  border: none;
  background-color: transparent;
  height: 20px;
  color: #1890ff;
  padding: 0px 5px !important;
  outline: none;
  vertical-align: middle;
  overflow: hidden;
}
.antui-datatable .table-row-button button > i,
.antui-datatable .table-row-button a > i {
  font-size: 20px;
  vertical-align: top;
  margin-left: 0 !important;
}
.antui-datatable .table-row-button button > i.anticon-spin + .anticon,
.antui-datatable .table-row-button a > i.anticon-spin + .anticon {
  display: none;
}
.antui-datatable .table-row-button button:hover,
.antui-datatable .table-row-button a:hover,
.antui-datatable .table-row-button button:focus,
.antui-datatable .table-row-button a:focus {
  background-color: transparent;
  color: #74bcff;
}
.antui-datatable .table-row-button button:active,
.antui-datatable .table-row-button a:active,
.antui-datatable .table-row-button button.active,
.antui-datatable .table-row-button a.active {
  background-color: transparent;
}
.antui-datatable .table-row-button button.disabled,
.antui-datatable .table-row-button a.disabled,
.antui-datatable .table-row-button button[disabled],
.antui-datatable .table-row-button a[disabled],
fieldset[disabled] .antui-datatable .table-row-button button,
fieldset[disabled] .antui-datatable .table-row-button a,
.antui-datatable .table-row-button button.disabled:hover,
.antui-datatable .table-row-button a.disabled:hover,
.antui-datatable .table-row-button button[disabled]:hover,
.antui-datatable .table-row-button a[disabled]:hover,
fieldset[disabled] .antui-datatable .table-row-button button:hover,
fieldset[disabled] .antui-datatable .table-row-button a:hover,
.antui-datatable .table-row-button button.disabled:focus,
.antui-datatable .table-row-button a.disabled:focus,
.antui-datatable .table-row-button button[disabled]:focus,
.antui-datatable .table-row-button a[disabled]:focus,
fieldset[disabled] .antui-datatable .table-row-button button:focus,
fieldset[disabled] .antui-datatable .table-row-button a:focus,
.antui-datatable .table-row-button button.disabled:active,
.antui-datatable .table-row-button a.disabled:active,
.antui-datatable .table-row-button button[disabled]:active,
.antui-datatable .table-row-button a[disabled]:active,
fieldset[disabled] .antui-datatable .table-row-button button:active,
fieldset[disabled] .antui-datatable .table-row-button a:active,
.antui-datatable .table-row-button button.disabled.active,
.antui-datatable .table-row-button a.disabled.active,
.antui-datatable .table-row-button button[disabled].active,
.antui-datatable .table-row-button a[disabled].active,
fieldset[disabled] .antui-datatable .table-row-button button.active,
fieldset[disabled] .antui-datatable .table-row-button a.active {
  background-color: transparent;
  color: #ccc;
}
.antui-datatable .ant-form-item {
  margin-bottom: 0;
  position: relative;
  left: -12px;
}

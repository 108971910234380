.realtime-alarm-modal {
  .realtime-alarm-modal-info {
    margin-top: 10px;
    width: 100%;
    height: 299px;
    overflow-y: auto;
    padding-left: 10px;
    .realtime-alarm-modal-row {
      display: flex;
      line-height: 1.5;
      margin-bottom: 8px;
      & > .realtime-alarm-modal-row-left {
        width: 76px;
        flex-shrink: 0;
      }
      & > .realtime-alarm-modal-row-right {

      }
    }
  }
  .realtime-alarm-modal-body {
    display: flex;
    height: calc(90vh - 120px);
    line-height: 1.5;
    margin-bottom: 8px;
    & > .realtime-alarm-modal-body-device {
      width: 50%;
      overflow-y: auto;
    }
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@value NORMAL: #6EEF95;
@value ALARM: #EF4A4A;
@value LOSE: #A86EEF;
@value OFFLINE: #BEBEBE;
@value NOTACTIVE: #9ECCFF;
@value ARM: #6EEF95;
@value DISARM: #EF4A4A;
@value ENABLE: #6EEF95;
@value DISABLE: #EF4A4A;
@value HANDLE: #6EEF95;
@value UN_HANDLE: #EF4A4A;
@value READ: #6EEF95;
@value UN_READ: #EF4A4A;
@value BLUE: #03B4FF;
@value SMOKECONCENTRATION: #2FC25B;
@value TEMPERATURE: #2FC25B;
@value SNR: #2FC25B;
@value SIGNALSTRENGTH: #2FC25B;
@value BATTERYLEVEL: #2FC25B;
@value SMOKEBATTERY: #2FC25B;
@value LEAKAGEVALUE: #F57C00;
@value LEAKAGE1VALUE: #F57C00;
@value LEAKAGE2VALUE: #1976D2;
@value CURRENTAVALUE: #FBC02D;
@value CURRENTBVALUE: #388E3C;
@value CURRENTCVALUE: #D32F2F;
@value VOLTAGEAVALUE: #FBC02D;
@value VOLTAGEBVALUE: #388E3C;
@value VOLTAGECVALUE: #D32F2F;
@value TEMP1VALUE: #FBC02D;
@value TEMP2VALUE: #388E3C;
@value TEMP3VALUE: #D32F2F;
@value TEMP4VALUE: #5D4037;
@value DEVICETEMPVALUE: #00796B;
@value POWERFACTORA: #FBC02D;
@value POWERFACTORB: #388E3C;
@value POWERFACTORC: #D32F2F;
@value ACTIVEPOWERA: #FBC02D;
@value ACTIVEPOWERB: #388E3C;
@value ACTIVEPOWERC: #D32F2F;
@value KWAVALUE: #FBC02D;
@value KWBVALUE: #388E3C;
@value KWCVALUE: #D32F2F;
@value BATTERY: #2FC25B;
@value SIGNAL: #2FC25B;
@value RSSI: #2FC25B;
@value FINISH: #00DE73;
@value UN_FINISH: #BEBEBE;
@value SUBMIT: #FFBB37;
@value HANDLE: #00AF4C;
@value REJECT: #EF4A4A;
@value GREEN: #00DE73;
@value RED: #EF4A4A;
@value WHITE: #FFFFFF;
@value YELLOW: #FFFF00;
@value ORANGE: #FFA500;
@value UNIT: #0CD9FF;
@value ORGANS: #FFBB37;
.lineTest {
  border: 1px solid #1976D2;
}
.vCenter {
  display: flex;
  align-items: center;
}
.hCenter {
  display: flex;
  justify-content: center;
}
.textHide {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ant-table-wrapper {
  zoom: 1;
}
.ant-table-wrapper::before,
.ant-table-wrapper::after {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  clear: both;
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  clear: both;
}
.ant-table-body {
  transition: opacity 0.3s;
}
.ant-table-empty .ant-table-body {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 4px 4px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-layout-fixed table {
  table-layout: fixed;
}
.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 100%;
  color: #bfbfbf;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .anticon-filter > svg,
.ant-table-thead > tr > th .ant-table-filter-icon > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -6px;
}
.ant-table-thead > tr > th .ant-table-filter-selected.anticon {
  color: #1890ff;
}
.ant-table-thead > tr > th .ant-table-column-sorter {
  display: table-cell;
  vertical-align: middle;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
  height: 1em;
  margin-top: 0.35em;
  margin-left: 0.57142857em;
  color: #bfbfbf;
  line-height: 1em;
  text-align: center;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  display: inline-block;
  font-size: 12px;
  font-size: 11px \9;
  transform: scale(0.91666667) rotate(0deg);
  display: block;
  height: 1em;
  line-height: 1em;
  transition: all 0.3s;
}
:root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up,
:root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  font-size: 12px;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  color: #1890ff;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full {
  margin-top: -0.15em;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-up,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
  height: 0.5em;
  line-height: 0.5em;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
  margin-top: 0.125em;
}
.ant-table-thead > tr > th.ant-table-column-has-actions {
  position: relative;
  background-clip: padding-box;
  /* stylelint-disable-next-line */
  -webkit-background-clip: border-box;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 30px !important;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:active,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters {
  cursor: pointer;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-up:not(.on),
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-down:not(.on) {
  color: rgba(0, 0, 0, 0.45);
}
.ant-table-thead > tr > th .ant-table-header-column {
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters {
  display: table;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > .ant-table-column-title {
  display: table-cell;
  vertical-align: middle;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > *:not(.ant-table-column-sorter) {
  position: relative;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters:hover::before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead > tr > th.ant-table-column-has-sorters {
  user-select: none;
}
.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 4px;
}
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 4px;
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
  transition: all 0.3s, border 0s;
}
.ant-table-thead > tr,
.ant-table-tbody > tr {
  transition: all 0.3s, height 0s;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #e6f7ff;
}
.ant-table-thead > tr.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-tbody > tr.ant-table-row-selected > td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-thead > tr:hover.ant-table-row-selected > td,
.ant-table-tbody > tr:hover.ant-table-row-selected > td {
  background: #fafafa;
}
.ant-table-thead > tr:hover.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-tbody > tr:hover.ant-table-row-selected > td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-thead > tr:hover {
  background: none;
}
.ant-table-footer {
  position: relative;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
.ant-table-footer::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fafafa;
  content: '';
}
.ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #e8e8e8;
}
.ant-table-title {
  position: relative;
  top: 1px;
  padding: 16px 0;
  border-radius: 4px 4px 0 0;
}
.ant-table.ant-table-bordered .ant-table-title {
  padding-right: 16px;
  padding-left: 16px;
  border: 1px solid #e8e8e8;
}
.ant-table-title + .ant-table-content {
  position: relative;
  border-radius: 4px 4px 0 0;
}
.ant-table-bordered .ant-table-title + .ant-table-content,
.ant-table-bordered .ant-table-title + .ant-table-content table,
.ant-table-bordered .ant-table-title + .ant-table-content .ant-table-thead > tr:first-child > th {
  border-radius: 0;
}
.ant-table-without-column-header .ant-table-title + .ant-table-content,
.ant-table-without-column-header table {
  border-radius: 0;
}
.ant-table-without-column-header.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-top: 1px solid #e8e8e8;
  border-radius: 4px;
}
.ant-table-tbody > tr.ant-table-row-selected td {
  color: inherit;
  background: #fafafa;
}
.ant-table-thead > tr > th.ant-table-column-sort {
  background: #f5f5f5;
}
.ant-table-tbody > tr > td.ant-table-column-sort {
  background: rgba(0, 0, 0, 0.01);
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-expand-icon-th,
.ant-table-row-expand-icon-cell {
  width: 50px;
  min-width: 50px;
  text-align: center;
}
.ant-table-header {
  overflow: hidden;
  background: #fafafa;
}
.ant-table-header table {
  border-radius: 4px 4px 0 0;
}
.ant-table-loading {
  position: relative;
}
.ant-table-loading .ant-table-body {
  background: #fff;
  opacity: 0.5;
}
.ant-table-loading .ant-table-spin-holder {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 20px;
  margin-left: -30px;
  line-height: 20px;
}
.ant-table-loading .ant-table-with-pagination {
  margin-top: -20px;
}
.ant-table-loading .ant-table-without-pagination {
  margin-top: 10px;
}
.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
  border: 1px solid #e8e8e8;
  border-right: 0;
  border-bottom: 0;
}
.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header > table {
  border-bottom: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header + .ant-table-body > table,
.ant-table-bordered.ant-table-fixed-header .ant-table-body-inner > table {
  border-top: 0;
}
.ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #e8e8e8;
}
.ant-table-placeholder {
  position: relative;
  z-index: 1;
  margin-top: -1px;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
.ant-table-pagination.ant-pagination {
  float: right;
  margin: 16px 0;
}
.ant-table-filter-dropdown {
  position: relative;
  min-width: 96px;
  margin-left: -8px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  border: 0;
  border-radius: 4px 4px 0 0;
  box-shadow: none;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
  padding-right: 0;
}
.ant-table-filter-dropdown .ant-dropdown-menu-sub {
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-submenu-contain-selected .ant-dropdown-menu-submenu-title::after {
  color: #1890ff;
  font-weight: bold;
  text-shadow: 0 0 2px #bae7ff;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item {
  overflow: hidden;
}
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-item:last-child,
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-submenu:last-child .ant-dropdown-menu-submenu-title {
  border-radius: 0;
}
.ant-table-filter-dropdown-btns {
  padding: 7px 8px;
  overflow: hidden;
  border-top: 1px solid #e8e8e8;
}
.ant-table-filter-dropdown-link {
  color: #1890ff;
}
.ant-table-filter-dropdown-link:hover {
  color: #40a9ff;
}
.ant-table-filter-dropdown-link:active {
  color: #096dd9;
}
.ant-table-filter-dropdown-link.confirm {
  float: left;
}
.ant-table-filter-dropdown-link.clear {
  float: right;
}
.ant-table-selection {
  white-space: nowrap;
}
.ant-table-selection-select-all-custom {
  margin-right: 4px !important;
}
.ant-table-selection .anticon-down {
  color: #bfbfbf;
  transition: all 0.3s;
}
.ant-table-selection-menu {
  min-width: 96px;
  margin-top: 5px;
  margin-left: -30px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-selection-menu .ant-action-down {
  color: #bfbfbf;
}
.ant-table-selection-down {
  display: inline-block;
  padding: 0;
  line-height: 1;
  cursor: pointer;
}
.ant-table-selection-down:hover .anticon-down {
  color: rgba(0, 0, 0, 0.6);
}
.ant-table-row-expand-icon {
  color: #1890ff;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  display: inline-block;
  width: 17px;
  height: 17px;
  color: inherit;
  line-height: 13px;
  text-align: center;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
  user-select: none;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #40a9ff;
}
.ant-table-row-expand-icon:active {
  color: #096dd9;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentColor;
}
.ant-table-row-expanded::after {
  content: '-';
}
.ant-table-row-collapsed::after {
  content: '+';
}
.ant-table-row-spaced {
  visibility: hidden;
}
.ant-table-row-spaced::after {
  content: '.';
}
.ant-table-row-cell-ellipsis,
.ant-table-row-cell-ellipsis .ant-table-column-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-table-row-cell-ellipsis .ant-table-column-title {
  display: block;
}
.ant-table-row-cell-break-word {
  word-wrap: break-word;
  word-break: break-word;
}
tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  background: #fbfbfb;
}
tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -16px -16px -17px;
}
.ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 8px;
}
.ant-table-scroll {
  overflow: auto;
  overflow-x: hidden;
}
.ant-table-scroll table {
  min-width: 100%;
}
.ant-table-scroll table .ant-table-fixed-columns-in-body:not([colspan]) {
  color: transparent;
}
.ant-table-scroll table .ant-table-fixed-columns-in-body:not([colspan]) > * {
  visibility: hidden;
}
.ant-table-body-inner {
  height: 100%;
}
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  position: relative;
  background: #fff;
}
.ant-table-fixed-header .ant-table-body-inner {
  overflow: scroll;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  margin-bottom: -20px;
  padding-bottom: 20px;
  overflow: scroll;
  opacity: 0.9999;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
  border: 1px solid #e8e8e8;
  border-width: 0 0 1px 0;
}
.ant-table-hide-scrollbar {
  scrollbar-color: transparent transparent;
  min-width: unset;
}
.ant-table-hide-scrollbar::-webkit-scrollbar {
  min-width: inherit;
  background-color: transparent;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
  border: 1px solid #e8e8e8;
  border-width: 1px 1px 1px 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header.ant-table-hide-scrollbar .ant-table-thead > tr:only-child > th:last-child {
  border-right-color: transparent;
}
.ant-table-fixed-left,
.ant-table-fixed-right {
  position: absolute;
  top: 0;
  z-index: 1;
  overflow: hidden;
  border-radius: 0;
  transition: box-shadow 0.3s ease;
}
.ant-table-fixed-left table,
.ant-table-fixed-right table {
  width: auto;
  background: #fff;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
.ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
  border-radius: 0;
}
.ant-table-fixed-left {
  left: 0;
  box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-left .ant-table-header {
  overflow-y: hidden;
}
.ant-table-fixed-left .ant-table-body-inner {
  margin-right: -20px;
  padding-right: 20px;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  padding-right: 0;
}
.ant-table-fixed-left,
.ant-table-fixed-left table {
  border-radius: 4px 0 0 0;
}
.ant-table-fixed-left .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 0;
}
.ant-table-fixed-right {
  right: 0;
  box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-right,
.ant-table-fixed-right table {
  border-radius: 0 4px 0 0;
}
.ant-table-fixed-right .ant-table-expanded-row {
  color: transparent;
  pointer-events: none;
}
.ant-table-fixed-right .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 0;
}
.ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  box-shadow: none;
}
.ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  box-shadow: none;
}
.ant-table colgroup > col.ant-table-selection-col {
  width: 60px;
}
.ant-table-thead > tr > th.ant-table-selection-column-custom .ant-table-selection {
  margin-right: -15px;
}
.ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: center;
}
.ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
.ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
.ant-table-row[class*='ant-table-row-level-0'] .ant-table-selection-column > span {
  display: inline-block;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
/**
* Another fix of Firefox:
*/
@supports (-moz-appearance: meterbar) {
  .ant-table-thead > tr > th.ant-table-column-has-actions {
    background-clip: padding-box;
  }
}
.ant-table-middle > .ant-table-title,
.ant-table-middle > .ant-table-content > .ant-table-footer {
  padding: 12px 8px;
}
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 12px 8px;
}
.ant-table-middle tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -12px -8px -13px;
}
.ant-table-small {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
.ant-table-small > .ant-table-title,
.ant-table-small > .ant-table-content > .ant-table-footer {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-title {
  top: 0;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-footer {
  background-color: transparent;
  border-top: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-footer::before {
  background-color: transparent;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  border: 0;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th {
  background-color: transparent;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr {
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort {
  background-color: rgba(0, 0, 0, 0.01);
}
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  padding: 0;
}
.ant-table-small > .ant-table-content .ant-table-header {
  background-color: transparent;
  border-radius: 4px 4px 0 0;
}
.ant-table-small > .ant-table-content .ant-table-placeholder,
.ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 0;
}
.ant-table-small.ant-table-bordered {
  border-right: 0;
}
.ant-table-small.ant-table-bordered .ant-table-title {
  border: 0;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-content {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer {
  border: 0;
  border-top: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer::before {
  display: none;
}
.ant-table-small.ant-table-bordered .ant-table-placeholder {
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}
.ant-table-small.ant-table-bordered .ant-table-thead > tr > th.ant-table-row-cell-last,
.ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: none;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
.ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -8px -8px -9px;
}
.ant-table-small.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  border-radius: 0 0 4px 4px;
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #1890ff;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.checkbox {
  font-size: 14px !important;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFF !important;
  line-height: 1.3 !important;
}
.checkbox > span {
  display: inline-flex;
}
.checkbox :global .ant-checkbox-indeterminate .ant-checkbox-inner {
  background: rgba(0, 0, 0, 0.45) !important;
}
.checkbox :global .ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.checkbox :global .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.45);
  border: 0.05rem solid rgba(255, 255, 255, 0.45);
}
.checkbox :global .ant-checkbox-inner::after {
  width: 6px;
  height: 9px;
  border-bottom: 2px solid #0CD9FF;
  border-right: 2px solid #0CD9FF;
}
.checkbox :global .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.checkbox :global .ant-checkbox:hover .ant-checkbox-inner,
.checkbox :global .ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 0.05rem solid #0CD9FF;
}
.checkbox :global .ant-checkbox-input:focus + .ant-checkbox-inner,
.checkbox :global .ant-checkbox-wrapper:focus + .ant-checkbox-inner,
.checkbox :global .ant-checkbox:focus + .ant-checkbox-inner {
  border: 0.05rem solid #0CD9FF;
}
.checkbox :global .ant-checkbox-input:hover .ant-checkbox-inner,
.checkbox :global .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.checkbox :global .ant-checkbox:hover .ant-checkbox-inner {
  border: 0.05rem solid #0CD9FF;
}
.checkbox :global .ant-checkbox-checked .ant-checkbox-inner {
  background: rgba(0, 0, 0, 0.45);
  border: 0.05rem solid #0CD9FF;
}
.checkbox :global .ant-checkbox-checked .ant-checkbox-inner::after {
  width: 6px;
  height: 9px;
  border-bottom: 2px solid #0CD9FF;
  border-right: 2px solid #0CD9FF;
}
.checkbox :global .ant-checkbox-checked::after {
  border: 0.05rem solid #0CD9FF;
  border-radius: 2px;
}
.checkbox :global .ant-checkbox-indeterminate .ant-checkbox-inner {
  border: 0.05rem solid #0CD9FF;
}
.checkbox :global .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  width: 8px;
  height: 8px;
  background-color: #0CD9FF;
}
.table {
  width: 100%;
  height: 100%;
}
.table :global .ant-table-hide-scrollbar {
  background: #f6f6f6;
}
.table :global .ant-table-body {
  overflow: auto !important;
}
.table :global .ant-table-body-outer {
  margin-bottom: -5px !important;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table {
  border-radius: 0;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container {
  border: 0.05rem solid rgba(255, 255, 255, 0.25);
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child),
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child),
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child),
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr:not(:last-child),
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr:not(:last-child),
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr:not(:last-child),
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr:not(:last-child),
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr:not(:last-child),
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr:not(:last-child),
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr:not(:last-child),
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr:not(:last-child),
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr:not(:last-child),
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td {
  border-right: 0.05rem solid rgba(255, 255, 255, 0.25);
  border-bottom: 0.05rem solid rgba(255, 255, 255, 0.25);
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed .ant-table-tbody,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body .ant-table-tbody {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed .ant-table-tbody > tr.ant-table-row:nth-child(odd),
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body .ant-table-tbody > tr.ant-table-row:nth-child(odd) {
  background: #f6f6f6;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed .ant-table-tbody > tr.ant-table-row:nth-child(odd) .ant-table-cell-fix-left,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body .ant-table-tbody > tr.ant-table-row:nth-child(odd) .ant-table-cell-fix-left,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed .ant-table-tbody > tr.ant-table-row:nth-child(odd) .ant-table-cell-fix-right,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body .ant-table-tbody > tr.ant-table-row:nth-child(odd) .ant-table-cell-fix-right {
  background: #f6f6f6;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed .ant-table-tbody > tr.ant-table-row:nth-child(even),
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body .ant-table-tbody > tr.ant-table-row:nth-child(even) {
  background: #ffffff;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed .ant-table-tbody > tr.ant-table-row:nth-child(even) .ant-table-cell-fix-left,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body .ant-table-tbody > tr.ant-table-row:nth-child(even) .ant-table-cell-fix-left,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed .ant-table-tbody > tr.ant-table-row:nth-child(even) .ant-table-cell-fix-right,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body .ant-table-tbody > tr.ant-table-row:nth-child(even) .ant-table-cell-fix-right {
  background: #ffffff;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed .ant-table-tbody > tr.ant-table-row-selected > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body .ant-table-tbody > tr.ant-table-row-selected > td {
  background: rgba(250, 250, 250, 0.25);
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed .ant-table-tbody > tr.ant-table-placeholder,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body .ant-table-tbody > tr.ant-table-placeholder {
  color: #fff;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed .ant-table-tbody > tr.ant-table-placeholder:hover > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: rgba(0, 0, 0, 0);
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed .ant-table-tbody > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body .ant-table-tbody > tr > td {
  padding: 8px;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed .ant-table-tbody .ant-table-placeholder .ant-table-cell .ant-empty,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body .ant-table-tbody .ant-table-placeholder .ant-table-cell .ant-empty {
  margin: 32px 0;
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed .ant-table-tbody .ant-table-placeholder .ant-table-cell .ant-empty-image,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body .ant-table-tbody .ant-table-placeholder .ant-table-cell .ant-empty-image {
  height: 40px;
  margin-bottom: 8px;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed .ant-table-tbody .ant-table-placeholder .ant-table-cell .ant-empty-image svg,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body .ant-table-tbody .ant-table-placeholder .ant-table-cell .ant-empty-image svg {
  width: 64px;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-thead > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-thead > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-tbody > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-tbody > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table tfoot > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table tfoot > tr > td,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-thead > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-thead > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-tbody > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-tbody > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table tfoot > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table tfoot > tr > th {
  padding: 10px;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header {
  font-size: 14px;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead .ant-table-cell-scrollbar,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead .ant-table-cell-scrollbar {
  box-shadow: 0 0 0 0;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th {
  color: #666;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 700;
  background: #f6f6f6;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper {
  font-size: 14px !important;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFF !important;
  line-height: 1.3 !important;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper > span,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper > span {
  display: inline-flex;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-indeterminate .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-indeterminate .ant-checkbox-inner {
  background: rgba(0, 0, 0, 0.45) !important;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox + span,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.45);
  border: 0.05rem solid rgba(255, 255, 255, 0.45);
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-inner::after,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-inner::after {
  width: 6px;
  height: 9px;
  border-bottom: 2px solid #0CD9FF;
  border-right: 2px solid #0CD9FF;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox:hover .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox:hover .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-input:focus + .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 0.05rem solid #0CD9FF;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-input:focus + .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-input:focus + .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-wrapper:focus + .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-wrapper:focus + .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox:focus + .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox:focus + .ant-checkbox-inner {
  border: 0.05rem solid #0CD9FF;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-input:hover .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-input:hover .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox:hover .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox:hover .ant-checkbox-inner {
  border: 0.05rem solid #0CD9FF;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-checked .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-checked .ant-checkbox-inner {
  background: rgba(0, 0, 0, 0.45);
  border: 0.05rem solid #0CD9FF;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-checked .ant-checkbox-inner::after,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-checked .ant-checkbox-inner::after {
  width: 6px;
  height: 9px;
  border-bottom: 2px solid #0CD9FF;
  border-right: 2px solid #0CD9FF;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-checked::after,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-checked::after {
  border: 0.05rem solid #0CD9FF;
  border-radius: 2px;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-indeterminate .ant-checkbox-inner,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-indeterminate .ant-checkbox-inner {
  border: 0.05rem solid #0CD9FF;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-fixed-right .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-indeterminate .ant-checkbox-inner::after,
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-header .ant-table-thead > tr > th .ant-table-selection .ant-checkbox-wrapper :global .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  width: 8px;
  height: 8px;
  background-color: #0CD9FF;
}
.table {
  width: 100%;
  height: 100%;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-header .ant-table-thead > tr > th {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65);
  background: rgba(255, 255, 255, 0.1);
  border-bottom: 0.05rem solid rgba(255, 255, 255, 0.2);
  line-height: 1.3;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body .ant-table-tbody > tr > td {
  border-bottom: 0.05rem solid rgba(255, 255, 255, 0.2);
  line-height: 1.3;
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body .ant-table-tbody > tr.ant-table-row:nth-child(odd) {
  background: rgba(50, 63, 86, 0);
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body .ant-table-tbody > tr.ant-table-row:nth-child(even) {
  background: rgba(0, 13, 42, 0);
}
.table :global .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body .ant-table-tbody > tr.ant-table-row:hover > td {
  background: rgba(0, 0, 0, 0.4);
}

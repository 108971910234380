/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.search-box {
  padding-top: 45px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.95) 20%, rgba(0, 0, 0, 0.75) 60%, transparent) !important;
}
.search-box .search-box-input {
  height: 160px;
  width: 80%;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
}
.search-box .search-box-input input {
  flex: auto;
  transition: all 0.2s;
  outline: 0;
  font-size: 6rem;
  font-weight: 100;
  width: 100%;
  background: none;
  color: #fff;
  border: 0;
}
@media screen and (max-width: 1000px) {
  .search-box .search-box-input input {
    font-size: 3rem;
  }
}
@media screen and (max-width: 1000px) {
  .search-box .search-box-input {
    height: 80px;
  }
}
.search-box .search-box-input .search-box-btn {
  flex: none;
  font-size: 6rem;
  color: #444;
  cursor: pointer;
}
.search-box .search-box-input .search-box-btn:hover {
  color: #888;
}
.search-box .search-box-input .search-box-btn:active {
  color: #444;
}
@media screen and (max-width: 1000px) {
  .search-box .search-box-input .search-box-btn {
    font-size: 3rem;
  }
}
.search-box .search-box-content {
  width: 80%;
  margin: 10px auto;
}
.search-box .search-box-content .ant-radio-wrapper {
  font-size: 15px;
  color: #999;
}
.bg-image {
  background: url('../../../assets/images/navbar_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.weather {
  float: left;
  line-height: 64px;
  font-size: 18px;
  padding-left: 30px;
}
.weather > div {
  float: left;
  padding-right: 25px;
}
.weather > div > img {
  float: left;
  margin-top: 15px;
}
.weather > div > div {
  float: left;
}
.navbar {
  z-index: 1030;
  margin-bottom: 0;
  height: 60px;
  color: #666;
  background-color: #fff;
  border-radius: 0;
  border: 0;
  border-bottom: 0;
  border-bottom: 1px solid #e0e0e0;
  padding-left: 0;
  list-style: none;
  user-select: none;
  overflow: hidden;
}
.navbar i {
  position: relative;
  top: -1px;
  vertical-align: middle;
}
.navbar.navbar-sm .navbar-branding {
  width: 60px;
}
.navbar.navbar-sm .navbar-branding .navbar-brand {
  width: 0;
  padding-left: 0;
}
.navbar .navbar-branding {
  position: relative;
  overflow: hidden;
  float: left;
  width: 230px;
  height: 60px;
  margin-right: 10px;
  background-color: transparent;
  transition: all 0.2s ease;
}
.navbar .navbar-branding .navbar-brand {
  color: #666;
  height: 60px;
  line-height: 58px;
  padding: 0;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.6px;
  padding-left: 15px;
  display: inline-block;
  overflow: hidden;
  width: 170px;
}
.navbar .navbar-branding .navbar-brand b {
  font-weight: bold;
  padding-right: 1px;
}
.navbar .navbar-branding .navbar-brand img {
  width: 35px;
  height: auto;
  margin-right: 10px;
}
.navbar .navbar-branding .toggle_sidemenu_l {
  float: right;
  cursor: pointer;
  font-size: 15px;
  line-height: 58px;
  max-height: 60px;
  width: 60px;
  text-align: center;
  -webkit-touch-callout: none;
  user-select: none;
}
.navbar .navbar-nav.navbar-left {
  float: left;
  position: relative;
  max-height: 60px;
}
.navbar .navbar-nav.navbar-right {
  float: right;
  margin: 0 15px 0 0;
}
.navbar .navbar-nav.navbar-right li {
  float: left;
  position: relative;
  display: block;
}
.navbar .navbar-nav.navbar-right li .navbar-btn {
  padding: 0 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.navbar .navbar-nav.navbar-right li .navbar-btn .btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  border-color: rgba(0, 0, 0, 0.07) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.18);
  white-space: nowrap;
  padding: 9px 12px;
  font-size: 13px;
  line-height: 1.49;
  user-select: none;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
}
.navbar .navbar-nav.navbar-right li .navbar-btn .btn i {
  font-size: 14px;
}
.navbar .navbar-nav.nav > li {
  float: left;
}
.navbar .nav > li > a {
  color: #666;
  font-weight: 600;
  height: 59px;
  line-height: 59px;
  position: relative;
  display: block;
  font-size: 18px;
  padding-right: 12px;
  padding-left: 12px;
}
.navbar .nav > li > a:focus,
.navbar .nav > li > a:hover {
  color: #222;
  background-color: #f7f7f7;
}
.navbar .navbar-form {
  width: auto;
  border: 0;
  margin: 15px 0;
  padding-bottom: 0;
  box-shadow: none;
  padding: 0px 11px;
  float: left;
}
.navbar .navbar-form.navbar-search input {
  width: 100%;
  font-size: 13px;
  height: 30px;
  line-height: 1.49;
  color: #555555;
  background-color: #fff;
  padding: 0 12px;
  transition: all 0.2s ease;
  box-shadow: none;
  border-radius: 20px;
  border: 1px solid #eee;
}
.navbar .navbar-form.navbar-search input:focus {
  outline: 0;
}
.navbar .navbar-form.navbar-search input:focus {
  background-color: #fafafa;
  border-color: #f0f0f0;
}
.navbar .navbar-form .form-group {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}
.navbar.bg-dark {
  color: #fff;
  background-color: #001529;
  border-color: transparent;
}
.navbar.bg-dark .navbar-branding {
  background-color: #000810;
}
.navbar.bg-dark .navbar-brand {
  color: #fff;
}
.navbar.bg-dark .nav > li > a {
  color: #fff;
}
.navbar.bg-dark .nav > li > a:hover {
  background-color: #000810;
}
.navbar.bg-primary {
  color: #fff;
  background-color: #4a89dc;
  border-color: transparent;
}
.navbar.bg-primary .navbar-branding {
  background-color: #357bd8;
}
.navbar.bg-primary .navbar-brand {
  color: #fff;
}
.navbar.bg-primary .nav > li > a {
  color: #fff;
}
.navbar.bg-primary .nav > li > a:hover {
  background-color: #357bd8;
}
.navbar.bg-light {
  background-color: #fff;
}
.navbar.bg-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: transparent;
}
.navbar.bg-info .navbar-branding {
  background-color: #00a5bb;
}
.navbar.bg-info .navbar-brand {
  color: #fff;
}
.navbar.bg-info .nav > li > a {
  color: #fff;
}
.navbar.bg-info .nav > li > a:hover {
  background-color: #00a5bb;
}
.navbar.bg-warning {
  color: #fff;
  background-color: #EB6100;
  border-color: transparent;
}
.navbar.bg-warning .navbar-branding {
  background-color: #d25600;
}
.navbar.bg-warning .navbar-brand {
  color: #fff;
}
.navbar.bg-warning .nav > li > a {
  color: #fff;
}
.navbar.bg-warning .nav > li > a:hover {
  background-color: #d25600;
}
.navbar.bg-danger {
  color: #fff;
  background-color: #f44336;
  border-color: transparent;
}
.navbar.bg-danger .navbar-branding {
  background-color: #f32c1e;
}
.navbar.bg-danger .navbar-brand {
  color: #fff;
}
.navbar.bg-danger .nav > li > a {
  color: #fff;
}
.navbar.bg-danger .nav > li > a:hover {
  background-color: #f32c1e;
}
.navbar.bg-alert {
  color: #fff;
  background-color: #9575CD;
  border-color: transparent;
}
.navbar.bg-alert .navbar-branding {
  background-color: #8762c6;
}
.navbar.bg-alert .navbar-brand {
  color: #fff;
}
.navbar.bg-alert .nav > li > a {
  color: #fff;
}
.navbar.bg-alert .nav > li > a:hover {
  background-color: #8762c6;
}
.navbar.bg-system {
  color: #fff;
  background-color: #48c9a9;
  border-color: transparent;
}
.navbar.bg-system .navbar-branding {
  background-color: #38bf9e;
}
.navbar.bg-system .navbar-brand {
  color: #fff;
}
.navbar.bg-system .nav > li > a {
  color: #fff;
}
.navbar.bg-system .nav > li > a:hover {
  background-color: #38bf9e;
}
.navbar.bg-success {
  color: #fff;
  background-color: #85d27a;
  border-color: transparent;
}
.navbar.bg-success .navbar-branding {
  background-color: #74cc67;
}
.navbar.bg-success .navbar-brand {
  color: #fff;
}
.navbar.bg-success .nav > li > a {
  color: #fff;
}
.navbar.bg-success .nav > li > a:hover {
  background-color: #74cc67;
}
.navbar.bg-grey {
  color: #fff;
  background-color: #30363e;
  border-color: transparent;
}
.navbar.bg-grey .navbar-branding {
  background-color: #252930;
}
.navbar.bg-grey .navbar-brand {
  color: #fff;
}
.navbar.bg-grey .nav > li > a {
  color: #fff;
}
.navbar.bg-grey .nav > li > a:hover {
  background-color: #252930;
}
.navbar-popup {
  z-index: 100;
  padding-top: 5px !important;
}
.navbar-popup .ant-popover-inner {
  border-top: 3px solid #1890ff;
}
.navbar-popup .ant-popover-inner .ant-popover-title {
  padding: 8px;
  background: #fafafa;
}
.navbar-popup .ant-popover-inner .ant-popover-inner-content {
  padding: 0;
}
.navbar-popup .ant-popover-inner .ant-popover-inner-content .list-group-item {
  width: 250px;
  border-bottom: 1px solid #e7e7e7;
  overflow: hidden;
}
.navbar-popup .ant-popover-inner .ant-popover-inner-content .list-group-item > a {
  display: block;
  padding: 10px 12px;
  color: #666666;
  line-height: 1.5;
}
.navbar-popup .ant-popover-inner .ant-popover-inner-content .list-group-item > a:hover {
  color: #595959;
  background-color: #f5f5f5;
}
.navbar-popup .ant-popover-inner .ant-popover-inner-content .list-group-item > a > .label {
  float: right;
}
.navbar-popup .ant-popover-inner .ant-popover-inner-content .list-group-item > a > i {
  padding-right: 5px;
}
.navbar-popup .ant-popover-inner .ant-popover-inner-content .list-group-item.dropdown-footer {
  text-align: center;
  background-color: #fafafa;
}
.navbar-popup .ant-popover-inner .ant-popover-inner-content .list-group-item.dropdown-footer:hover {
  background: #eee;
}
.navbar-popup .ant-popover-inner .ant-popover-inner-content .list-group-item.dropdown-footer:hover a {
  color: #1890ff;
}
.navbar-popup .ant-popover-arrow {
  top: 0px !important;
  background: #1890ff;
  width: 10px;
  height: 10px;
  z-index: -1;
}
.navbar-popup.dark .ant-popover-inner {
  border-top-color: #001529;
}
.navbar-popup.dark .ant-popover-arrow {
  background: #001529;
}
.navbar-popup.primary .ant-popover-inner {
  border-top-color: #4a89dc;
}
.navbar-popup.primary .ant-popover-arrow {
  background: #4a89dc;
}
.navbar-popup.info .ant-popover-inner {
  border-top-color: #00bcd4;
}
.navbar-popup.info .ant-popover-arrow {
  background: #00bcd4;
}
.navbar-popup.warning .ant-popover-inner {
  border-top-color: #EB6100;
}
.navbar-popup.warning .ant-popover-arrow {
  background: #EB6100;
}
.navbar-popup.danger .ant-popover-inner {
  border-top-color: #f44336;
}
.navbar-popup.danger .ant-popover-arrow {
  background: #f44336;
}
.navbar-popup.alert .ant-popover-inner {
  border-top-color: #9575CD;
}
.navbar-popup.alert .ant-popover-arrow {
  background: #9575CD;
}
.navbar-popup.system .ant-popover-inner {
  border-top-color: #48c9a9;
}
.navbar-popup.system .ant-popover-arrow {
  background: #48c9a9;
}
.navbar-popup.success .ant-popover-inner {
  border-top-color: #85d27a;
}
.navbar-popup.success .ant-popover-arrow {
  background: #85d27a;
}
.navbar-popup.dark .ant-popover-inner {
  border-top-color: #30363e;
}
.navbar-popup.grey .ant-popover-arrow {
  background: #30363e;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@value NORMAL: #6EEF95;
@value ALARM: #EF4A4A;
@value LOSE: #A86EEF;
@value OFFLINE: #BEBEBE;
@value NOTACTIVE: #9ECCFF;
@value ARM: #6EEF95;
@value DISARM: #EF4A4A;
@value ENABLE: #6EEF95;
@value DISABLE: #EF4A4A;
@value HANDLE: #6EEF95;
@value UN_HANDLE: #EF4A4A;
@value READ: #6EEF95;
@value UN_READ: #EF4A4A;
@value BLUE: #03B4FF;
@value SMOKECONCENTRATION: #2FC25B;
@value TEMPERATURE: #2FC25B;
@value SNR: #2FC25B;
@value SIGNALSTRENGTH: #2FC25B;
@value BATTERYLEVEL: #2FC25B;
@value SMOKEBATTERY: #2FC25B;
@value LEAKAGEVALUE: #F57C00;
@value LEAKAGE1VALUE: #F57C00;
@value LEAKAGE2VALUE: #1976D2;
@value CURRENTAVALUE: #FBC02D;
@value CURRENTBVALUE: #388E3C;
@value CURRENTCVALUE: #D32F2F;
@value VOLTAGEAVALUE: #FBC02D;
@value VOLTAGEBVALUE: #388E3C;
@value VOLTAGECVALUE: #D32F2F;
@value TEMP1VALUE: #FBC02D;
@value TEMP2VALUE: #388E3C;
@value TEMP3VALUE: #D32F2F;
@value TEMP4VALUE: #5D4037;
@value DEVICETEMPVALUE: #00796B;
@value POWERFACTORA: #FBC02D;
@value POWERFACTORB: #388E3C;
@value POWERFACTORC: #D32F2F;
@value ACTIVEPOWERA: #FBC02D;
@value ACTIVEPOWERB: #388E3C;
@value ACTIVEPOWERC: #D32F2F;
@value KWAVALUE: #FBC02D;
@value KWBVALUE: #388E3C;
@value KWCVALUE: #D32F2F;
@value BATTERY: #2FC25B;
@value SIGNAL: #2FC25B;
@value RSSI: #2FC25B;
@value FINISH: #00DE73;
@value UN_FINISH: #BEBEBE;
@value SUBMIT: #FFBB37;
@value HANDLE: #00AF4C;
@value REJECT: #EF4A4A;
@value GREEN: #00DE73;
@value RED: #EF4A4A;
@value WHITE: #FFFFFF;
@value YELLOW: #FFFF00;
@value ORANGE: #FFA500;
@value UNIT: #0CD9FF;
@value ORGANS: #FFBB37;
.lineTest {
  border: 1px solid #1976D2;
}
.vCenter {
  display: flex;
  align-items: center;
}
.hCenter {
  display: flex;
  justify-content: center;
}
.textHide {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.main {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  position: relative;
}
.main .more {
  width: 60px;
  height: 25px;
  position: absolute;
  bottom: -15px;
  left: 50%;
  margin-left: -30px;
  cursor: pointer;
  flex-direction: column;
  transition: all 0.3s;
  background: #FFF;
  border-radius: 4px 4px 0 0;
  border: 1px solid #d9d9d9;
  box-shadow: 0 8px 18px -3px #9e9e9e;
  align-items: center;
  display: flex;
  justify-content: center;
}
.main .more .icon {
  color: #000;
  font-size: 8px;
}
.main .more .text {
  color: #000;
  line-height: 1.3;
  font-size: 9px;
}
.main .more:hover {
  bottom: 0;
}
.main .form {
  width: 100%;
  height: auto;
}
.main .form .normalDiv,
.main .form .miniDiv {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  padding: 9px;
}
.main .form .normalDiv .actionDiv,
.main .form .miniDiv .actionDiv {
  width: 1px;
  height: auto;
  flex-grow: 1;
}
.main .form .normalDiv .searchDiv,
.main .form .miniDiv .searchDiv {
  width: auto;
  height: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.main .form .normalDiv .searchDiv .button,
.main .form .miniDiv .searchDiv .button {
  height: 32px;
  margin-right: 10px;
  flex-shrink: 0;
}
.main .form .normalDiv .searchDiv .button:last-child,
.main .form .miniDiv .searchDiv .button:last-child {
  margin: 0;
}
.main .form .normalDiv .searchDiv .formItemDiv,
.main .form .miniDiv .searchDiv .formItemDiv {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.main .form .normalDiv .searchDiv .formItemDiv .lable,
.main .form .miniDiv .searchDiv .formItemDiv .lable {
  width: 120px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.main .form .normalDiv .formItem,
.main .form .miniDiv .formItem {
  width: 100%;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}
.main .form .miniDiv {
  display: flex;
  align-items: center;
}
.main .form .miniDiv .inputGroup {
  display: flex;
  align-items: center;
}
.main .form .miniDiv .buttonGroup {
  display: flex;
  align-items: center;
}
.main .form .miniDiv .buttonGroup :global .ant-btn:first-child:not(:last-child),
.main .form .miniDiv .buttonGroup :global span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.main .form .normalDiv {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}
.main .form .normalDiv .searchDiv {
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.main .form .normalDiv .searchDiv .formItemDiv {
  margin: 0;
  padding: 10px;
}
.main .form .hide {
  display: none;
}

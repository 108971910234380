/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.notice-tab {
  width: 417px;
  height: 400px;
  overflow: hidden;
}
.notice-tab .ant-tabs-bar {
  border-bottom: 1px solid #e8e8e8;
  margin: 0;
  outline: none;
  -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.notice-tab .list-box {
  height: 400px;
  padding-bottom: 85px;
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.notice-tab .list-box::-webkit-scrollbar {
  width: 0 !important;
}
.notice-tab .notice-item {
  width: 100%;
  display: flex;
  font-size: 13px;
}
.notice-tab .notice-item .item-left {
  width: 55px;
  text-align: center;
}
.notice-tab .notice-item .item-left img {
  width: 35px;
  height: 35px;
}
.notice-tab .notice-item .item-right {
  flex: 1;
  word-break: break-all;
  padding-right: 5px;
}
.notice-tab .notice-item .item-right .time-box .item-button {
  color: #31bae7;
  float: right;
  margin-right: 30px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.screen-layout.fixed.ant-layout,
.screen-layout.fixed .full-layout.ant-layout {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

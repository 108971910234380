/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.basic-mask {
  z-index: 9998;
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}
.basic-mask .basic-mask-close {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
  font-size: 3rem;
  color: #bdbdbd;
  transition: color 0.3s;
}
.basic-mask .basic-mask-close:hover {
  color: #ddd;
}
.basic-mask .basic-mask-close:active {
  color: #bdbdbd;
}

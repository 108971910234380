/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@value NORMAL: #6EEF95;
@value ALARM: #EF4A4A;
@value LOSE: #A86EEF;
@value OFFLINE: #BEBEBE;
@value NOTACTIVE: #9ECCFF;
@value ARM: #6EEF95;
@value DISARM: #EF4A4A;
@value ENABLE: #6EEF95;
@value DISABLE: #EF4A4A;
@value HANDLE: #6EEF95;
@value UN_HANDLE: #EF4A4A;
@value READ: #6EEF95;
@value UN_READ: #EF4A4A;
@value BLUE: #03B4FF;
@value SMOKECONCENTRATION: #2FC25B;
@value TEMPERATURE: #2FC25B;
@value SNR: #2FC25B;
@value SIGNALSTRENGTH: #2FC25B;
@value BATTERYLEVEL: #2FC25B;
@value SMOKEBATTERY: #2FC25B;
@value LEAKAGEVALUE: #F57C00;
@value LEAKAGE1VALUE: #F57C00;
@value LEAKAGE2VALUE: #1976D2;
@value CURRENTAVALUE: #FBC02D;
@value CURRENTBVALUE: #388E3C;
@value CURRENTCVALUE: #D32F2F;
@value VOLTAGEAVALUE: #FBC02D;
@value VOLTAGEBVALUE: #388E3C;
@value VOLTAGECVALUE: #D32F2F;
@value TEMP1VALUE: #FBC02D;
@value TEMP2VALUE: #388E3C;
@value TEMP3VALUE: #D32F2F;
@value TEMP4VALUE: #5D4037;
@value DEVICETEMPVALUE: #00796B;
@value POWERFACTORA: #FBC02D;
@value POWERFACTORB: #388E3C;
@value POWERFACTORC: #D32F2F;
@value ACTIVEPOWERA: #FBC02D;
@value ACTIVEPOWERB: #388E3C;
@value ACTIVEPOWERC: #D32F2F;
@value KWAVALUE: #FBC02D;
@value KWBVALUE: #388E3C;
@value KWCVALUE: #D32F2F;
@value BATTERY: #2FC25B;
@value SIGNAL: #2FC25B;
@value RSSI: #2FC25B;
@value FINISH: #00DE73;
@value UN_FINISH: #BEBEBE;
@value SUBMIT: #FFBB37;
@value HANDLE: #00AF4C;
@value REJECT: #EF4A4A;
@value GREEN: #00DE73;
@value RED: #EF4A4A;
@value WHITE: #FFFFFF;
@value YELLOW: #FFFF00;
@value ORANGE: #FFA500;
@value UNIT: #0CD9FF;
@value ORGANS: #FFBB37;
.lineTest {
  border: 1px solid #1976D2;
}
.vCenter {
  display: flex;
  align-items: center;
}
.hCenter {
  display: flex;
  justify-content: center;
}
.textHide {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.deviceGroupManage {
  width: 100%;
  height: 60vh;
}
.deviceGroupManage .deviceBody {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}
.deviceGroupManage .deviceBody .deviceItem {
  width: 1px;
  height: 14.2856%;
  min-height: 80px;
}
.deviceGroupManage .deviceBody .deviceItem .deviceItemBody {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
}
.deviceGroupManage .deviceBody .deviceItem .deviceItemBody .image {
  width: 18%;
  height: 60px;
  object-fit: contain;
}
.deviceGroupManage .deviceBody .deviceItem .deviceItemBody .deleteImage {
  width: 29px;
  height: 29px;
  object-fit: contain;
  background: rgba(200, 200, 200, 0.2);
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);
  border-left: 1px solid rgba(200, 200, 200, 0.5);
  padding: 6px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.deviceGroupManage .deviceBody .deviceItem .deviceItemBody .info {
  margin-left: 9px;
}
.deviceGroupManage .deviceBody .deviceItem .deviceItemBody .info .text1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000;
  margin-bottom: 6px;
}
.deviceGroupManage .deviceBody .deviceItem .deviceItemBody .info .text2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
}
.deviceGroupManage .deviceBody .addItem .deviceItemBody {
  align-items: center;
  display: flex;
  justify-content: center;
}
.deviceGroupManage .deviceBody .addItem .deviceItemBody .image {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.deviceGroupManage .deviceBody .addItem .deviceItemBody .info {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
}

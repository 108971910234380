/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@value NORMAL: #6EEF95;
@value ALARM: #EF4A4A;
@value LOSE: #A86EEF;
@value OFFLINE: #BEBEBE;
@value NOTACTIVE: #9ECCFF;
@value ARM: #6EEF95;
@value DISARM: #EF4A4A;
@value ENABLE: #6EEF95;
@value DISABLE: #EF4A4A;
@value HANDLE: #6EEF95;
@value UN_HANDLE: #EF4A4A;
@value READ: #6EEF95;
@value UN_READ: #EF4A4A;
@value BLUE: #03B4FF;
@value SMOKECONCENTRATION: #2FC25B;
@value TEMPERATURE: #2FC25B;
@value SNR: #2FC25B;
@value SIGNALSTRENGTH: #2FC25B;
@value BATTERYLEVEL: #2FC25B;
@value SMOKEBATTERY: #2FC25B;
@value LEAKAGEVALUE: #F57C00;
@value LEAKAGE1VALUE: #F57C00;
@value LEAKAGE2VALUE: #1976D2;
@value CURRENTAVALUE: #FBC02D;
@value CURRENTBVALUE: #388E3C;
@value CURRENTCVALUE: #D32F2F;
@value VOLTAGEAVALUE: #FBC02D;
@value VOLTAGEBVALUE: #388E3C;
@value VOLTAGECVALUE: #D32F2F;
@value TEMP1VALUE: #FBC02D;
@value TEMP2VALUE: #388E3C;
@value TEMP3VALUE: #D32F2F;
@value TEMP4VALUE: #5D4037;
@value DEVICETEMPVALUE: #00796B;
@value POWERFACTORA: #FBC02D;
@value POWERFACTORB: #388E3C;
@value POWERFACTORC: #D32F2F;
@value ACTIVEPOWERA: #FBC02D;
@value ACTIVEPOWERB: #388E3C;
@value ACTIVEPOWERC: #D32F2F;
@value KWAVALUE: #FBC02D;
@value KWBVALUE: #388E3C;
@value KWCVALUE: #D32F2F;
@value BATTERY: #2FC25B;
@value SIGNAL: #2FC25B;
@value RSSI: #2FC25B;
@value FINISH: #00DE73;
@value UN_FINISH: #BEBEBE;
@value SUBMIT: #FFBB37;
@value HANDLE: #00AF4C;
@value REJECT: #EF4A4A;
@value GREEN: #00DE73;
@value RED: #EF4A4A;
@value WHITE: #FFFFFF;
@value YELLOW: #FFFF00;
@value ORANGE: #FFA500;
@value UNIT: #0CD9FF;
@value ORGANS: #FFBB37;
.lineTest {
  border: 1px solid #1976D2;
}
.vCenter {
  display: flex;
  align-items: center;
}
.hCenter {
  display: flex;
  justify-content: center;
}
.textHide {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  margin-right: 8px;
  white-space: nowrap;
  cursor: pointer;
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: sub;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #1890ff;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #1890ff;
}
.ant-radio-checked .ant-radio-inner::after {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 30px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s;
}
.ant-radio-button-wrapper a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-radio-button-wrapper > .ant-radio-button {
  display: block;
  width: 0;
  height: 0;
  margin-left: 0;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: -1px;
  display: block;
  width: 1px;
  height: 100%;
  background-color: #d9d9d9;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 4px 0 0 4px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 4px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #1890ff;
}
.ant-radio-button-wrapper:focus-within {
  outline: 3px solid rgba(24, 144, 255, 0.06);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #1890ff;
  background: #fff;
  border-color: #1890ff;
  box-shadow: -1px 0 0 0 #1890ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #1890ff !important;
  opacity: 0.1;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #1890ff;
  box-shadow: none !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #40a9ff;
  border-color: #40a9ff;
  box-shadow: -1px 0 0 0 #40a9ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #096dd9;
  border-color: #096dd9;
  box-shadow: -1px 0 0 0 #096dd9;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 3px solid rgba(24, 144, 255, 0.06);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #40a9ff;
  border-color: #40a9ff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #096dd9;
  border-color: #096dd9;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 3px solid rgba(24, 144, 255, 0.06);
}
.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #fff;
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
  .ant-radio {
    vertical-align: text-bottom;
  }
}
.radio {
  font-size: 14px !important;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFF !important;
  line-height: 1.3 !important;
}
.radio > span {
  display: inline-flex;
}
.radio :global .ant-radio-disabled + span {
  color: rgba(255, 255, 255, 0.65);
}
.radio :global .ant-radio-inner {
  width: 16px;
  height: 16px;
  background: rgba(0, 0, 0, 0.45);
  border: 0.05rem solid rgba(255, 255, 255, 0.45);
}
.radio :global .ant-radio-inner::after {
  top: 0.15rem;
  left: 0.15rem;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #0CD9FF;
}
.radio :global .ant-radio-wrapper,
.radio :global .ant-radio {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFF;
  line-height: 1.3;
}
.radio :global .ant-radio-wrapper :hover .ant-radio-inner,
.radio :global .ant-radio :hover .ant-radio-inner {
  border: 0.05rem solid #0CD9FF;
}
.radio :global .ant-radio-checked .ant-radio-inner {
  border: 0.05rem solid #0CD9FF;
}
.radio :global .ant-radio-checked::after {
  border: 0.05rem solid #0CD9FF;
}

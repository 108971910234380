/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@value NORMAL: #6EEF95;
@value ALARM: #EF4A4A;
@value LOSE: #A86EEF;
@value OFFLINE: #BEBEBE;
@value NOTACTIVE: #9ECCFF;
@value ARM: #6EEF95;
@value DISARM: #EF4A4A;
@value ENABLE: #6EEF95;
@value DISABLE: #EF4A4A;
@value HANDLE: #6EEF95;
@value UN_HANDLE: #EF4A4A;
@value READ: #6EEF95;
@value UN_READ: #EF4A4A;
@value BLUE: #03B4FF;
@value SMOKECONCENTRATION: #2FC25B;
@value TEMPERATURE: #2FC25B;
@value SNR: #2FC25B;
@value SIGNALSTRENGTH: #2FC25B;
@value BATTERYLEVEL: #2FC25B;
@value SMOKEBATTERY: #2FC25B;
@value LEAKAGEVALUE: #F57C00;
@value LEAKAGE1VALUE: #F57C00;
@value LEAKAGE2VALUE: #1976D2;
@value CURRENTAVALUE: #FBC02D;
@value CURRENTBVALUE: #388E3C;
@value CURRENTCVALUE: #D32F2F;
@value VOLTAGEAVALUE: #FBC02D;
@value VOLTAGEBVALUE: #388E3C;
@value VOLTAGECVALUE: #D32F2F;
@value TEMP1VALUE: #FBC02D;
@value TEMP2VALUE: #388E3C;
@value TEMP3VALUE: #D32F2F;
@value TEMP4VALUE: #5D4037;
@value DEVICETEMPVALUE: #00796B;
@value POWERFACTORA: #FBC02D;
@value POWERFACTORB: #388E3C;
@value POWERFACTORC: #D32F2F;
@value ACTIVEPOWERA: #FBC02D;
@value ACTIVEPOWERB: #388E3C;
@value ACTIVEPOWERC: #D32F2F;
@value KWAVALUE: #FBC02D;
@value KWBVALUE: #388E3C;
@value KWCVALUE: #D32F2F;
@value BATTERY: #2FC25B;
@value SIGNAL: #2FC25B;
@value RSSI: #2FC25B;
@value FINISH: #00DE73;
@value UN_FINISH: #BEBEBE;
@value SUBMIT: #FFBB37;
@value HANDLE: #00AF4C;
@value REJECT: #EF4A4A;
@value GREEN: #00DE73;
@value RED: #EF4A4A;
@value WHITE: #FFFFFF;
@value YELLOW: #FFFF00;
@value ORANGE: #FFA500;
@value UNIT: #0CD9FF;
@value ORGANS: #FFBB37;
.lineTest {
  border: 1px solid #1976D2;
}
.vCenter {
  display: flex;
  align-items: center;
}
.hCenter {
  display: flex;
  justify-content: center;
}
.textHide {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modalWrap {
  overflow: hidden;
}
/** 允许事件穿透过Modal的遮罩 */
.modalWrapPointerEvents {
  pointer-events: none;
}
.modalMain {
  padding-bottom: 0;
}
.modalMain .modalBody {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.modalMain .modalBody .leftMain {
  width: auto;
  height: auto;
  margin-right: 2px;
}
.modalMain .modalBody .rightMain {
  width: auto;
  height: auto;
  margin-left: 2px;
}
.modalMain .modalBody .mainBody {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  max-height: calc(90vh);
}
.modalMain .modalBody .mainBody .top {
  align-items: center;
  position: relative;
  padding: 16px 24px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
}
.modalMain .modalBody .mainBody .top .title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.1;
}
.modalMain .modalBody .mainBody .top .iconDiv {
  width: 50px;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  right: 0;
}
.modalMain .modalBody .mainBody .top .iconDiv .icon {
  font-size: 16px;
  line-height: 0;
  color: rgba(0, 0, 0, 0.45);
}
.modalMain .modalBody .mainBody .top .iconDiv:hover .icon {
  color: rgba(0, 0, 0, 0.75);
}
.modalMain .modalBody .mainBody .body {
  width: 100%;
  height: auto;
  overflow-y: auto;
  flex-grow: 1;
  font-size: 14px;
  line-height: 1.3;
  padding: 14px;
  color: #000;
}
.modalMain .modalBody .mainBody .bottom {
  padding: 10px 16px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  justify-content: flex-end;
}
.modalMain .modalBody .mainBody .bottom .bottons {
  display: flex;
}
.modalMain .modalBody .mainBody .bottom .bottons .button {
  height: 36px;
  margin-right: 10px;
}
.modalMain :global .ant-modal-content {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  pointer-events: none;
  box-shadow: none;
}
.modalMain :global .ant-modal-body {
  padding: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.list-footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  height: 45px;
  line-height: 45px;
  border-top: 1px solid #E8E8E8;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%
}

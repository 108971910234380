@import "~styles/common-styles.less";
@import "~antd/lib/table/style/index.less";
@import "./checkbox.module.less";

.table {
  .size(100%, 100%);

  :global {
    .ant-table-fixed-header
      > .ant-table-content
      > .ant-table-scroll
      > .ant-table-body {
    }
    .ant-table-hide-scrollbar {
      background: rgba(246, 246, 246, 1);
    }
    .ant-table-body {
      overflow: auto !important;
    }
    .ant-table-body-outer {
      margin-bottom: -5px !important;
    }
    .ant-spin-nested-loading {
      & .ant-spin-container {
        & .ant-table {
          border-radius: 0;

          &.ant-table-bordered {
            & > .ant-table-container {
              border: 0.05rem solid rgba(255, 255, 255, 0.25);

              & > .ant-table-content,
              & > .ant-table-header,
              & > .ant-table-body,
              & > .ant-table-summary {
                & > table {
                  & > thead,
                  & > tfoot,
                  & > tbody {
                    & > tr {
                      &:not(:last-child),
                      & > th,
                      & > td {
                        border-right: 0.05rem solid rgba(255, 255, 255, 0.25);
                        border-bottom: 0.05rem solid rgba(255, 255, 255, 0.25);
                      }
                    }
                  }
                }
              }
            }
          }

          & .ant-table-content {
            & .ant-table-fixed,
            & .ant-table-body {
              & .ant-table-tbody {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);

                & > tr {
                  &.ant-table-row {
                    &:nth-child(odd) {
                      background: rgba(246, 246, 246, 1);

                      & .ant-table-cell-fix-left,
                      & .ant-table-cell-fix-right {
                        background: rgba(246, 246, 246, 1);
                      }
                    }

                    &:nth-child(even) {
                      background: rgba(255, 255, 255, 1);

                      & .ant-table-cell-fix-left,
                      & .ant-table-cell-fix-right {
                        background: rgba(255, 255, 255, 1);
                      }
                    }
                  }

                  &.ant-table-row-selected {
                    & > td {
                      background: rgba(250, 250, 250, 0.25);
                    }
                  }

                  &.ant-table-placeholder {
                    color: #fff;

                    &:hover {
                      & > td {
                        background: rgba(0, 0, 0, 0);
                      }
                    }
                  }

                  & > td {
                    padding: 8px;
                  }
                }

                & .ant-table-placeholder {
                  & .ant-table-cell {
                    & .ant-empty {
                      margin: 32px 0;
                      color: rgba(255, 255, 255, 0.65);
                      font-size: 14px;
                    }

                    & .ant-empty-image {
                      height: 40px;
                      margin-bottom: 8px;

                      & svg {
                        width: 64px;
                      }
                    }
                  }
                }
              }
            }
            & .ant-table-fixed-right,
            & .ant-table-scroll {
              & .ant-table-thead,
              & .ant-table-tbody,
              & .ant-table tfoot {
                & > tr {
                  & > td,
                  & > th {
                    padding: 10px;
                  }
                }
              }

              & .ant-table-thead {
                & > tr {
                  & > th {
                  }
                }
              }

              & .ant-table-header {
                font-size: 14px;

                & .ant-table-thead {
                  & .ant-table-cell-scrollbar {
                    box-shadow: 0 0 0 0;
                  }

                  & > tr {
                    & > th {
                      color: #666;
                      font-size: 14px;
                      font-family: Microsoft YaHei;
                      font-weight: 700;
                      background: rgba(246, 246, 246, 1);

                      & .ant-table-selection {
                        & .ant-checkbox-wrapper {
                          .checkbox();
                        }
                      }
                    }
                  }
                }
              }
            }

            & .ant-table-scroll {
            }
          }
        }
      }
    }
  }
}

.row {
  :global {
  }
}

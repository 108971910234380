/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antui-transfer-tree {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}
.antui-transfer-tree-list {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  display: inline-block;
  border-radius: 4px;
  vertical-align: middle;
  position: relative;
  width: 200px;
  height: 300px;
  padding-top: 34px;
}
.antui-transfer-tree-list-header {
  padding: 6px 12px;
  border-radius: 4px 4px 0 0;
  background: #f9f9f9;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 2px solid #8cc8ff;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 34px;
}
.antui-transfer-tree-list-header.tree-title {
  color: #333;
}
.antui-transfer-tree-list-header-title {
  position: absolute;
  right: 12px;
}
.antui-transfer-tree-list-header-title a {
  color: #1890ff;
}
.antui-transfer-tree-list-header-title a:hover {
  color: #8cc8ff;
}
.antui-transfer-tree-list-body {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}
.antui-transfer-tree-list-body-search-wrapper {
  flex: none;
  padding: 4px;
}
.antui-transfer-tree-list-body-search-wrapper .antui-transfer-tree-list-search-action {
  color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  width: 28px;
  line-height: 32px;
  text-align: center;
}
.antui-transfer-tree-list-body-content {
  flex: auto;
  position: relative;
  height: 100%;
  overflow: auto;
}
.antui-transfer-tree-list-body-content.tree-content {
  padding: 0 4px;
}
.antui-transfer-tree-list-body-content.tree-content .tree-filter-result + .ant-tree {
  display: none;
}
.antui-transfer-tree-list-body-content .ant-tree li .ant-tree-node-content-wrapper {
  width: calc(100% - 24px);
}
.antui-transfer-tree-list-body-content .ant-tree li > span {
  vertical-align: middle;
}
.antui-transfer-tree-list-body-content-not-found {
  text-align: center;
  color: #ddd;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin: auto;
  transform: translateY(-50%);
}
.antui-transfer-tree-list-body-content .list-comp-item {
  position: relative;
  padding: 7px 16px;
  transition: all 0.3s ease;
}
.antui-transfer-tree-list-body-content .list-comp-item:hover {
  cursor: pointer;
  background-color: #e6f7ff;
}
.antui-transfer-tree-list-body-content .list-comp-item .list-comp-clear-item {
  position: absolute;
  right: 7px;
  top: 7px;
  color: #666;
  display: none;
  float: right;
  font-weight: bold;
}
.antui-transfer-tree-list-body-content .list-comp-item .list-comp-clear-item:hover {
  color: #f5222d;
}
.antui-transfer-tree-list-body-content .list-comp-item .list-comp-item-body {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.antui-transfer-tree-list-body-content .list-comp-item:hover .list-comp-clear-item {
  display: block;
}
.antui-transfer-tree-operation {
  display: inline-block;
  overflow: hidden;
  margin: 0 8px;
  vertical-align: middle;
}

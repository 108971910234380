/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-btn-new-driver {
  background-color: #EC6700;
  border-color: #d9d9d9;
  color: #fff;
}
.ant-btn-new-driver:hover,
.ant-btn-new-driver:focus {
  background-color: #EC9900;
  border-color: #d9d9d9;
  color: #fff;
}
.ant-btn-pass {
  background-color: #2EC94D;
  border-color: #2EC94D;
  color: #fff;
}
.ant-btn-pass:hover,
.ant-btn-pass:focus {
  background-color: #2EC94DBB;
  border-color: #2EC94DBB;
  color: #fff;
}

@import '~antd/lib/style/themes/default.less';
@import './common-color.less';

@BgColor: #050817;

.lineTest {
    border: 1px solid #1976D2;
}

.size(@width, @height) {
    width: @width;
    height: @height;
    // .lineTest();
}

.vCenter {
    display: flex;
    align-items: center;
}

.hCenter {
    display: flex;
    justify-content: center;
}

.textHide {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.imageBase(@width, @height) {
    .size(@width, @height);
    object-fit: contain;
}
.detail-item {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  h4 {
    margin-bottom: 5px;
  }
  .item-container {
    width: 100%;
    display: flex;
    padding: 3px 20px;
    .item-label {
      width: 100px;
    }
    .item-value {
      flex: 1;
    }
  }
  p {
    color: #c1c1c1;
    padding: 3px 20px;
  }
}

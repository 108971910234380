.tagMain {
  font-size: 0.6rem;
  line-height: 1.3;
  
  font-weight: 400;
  padding: 0.25rem;
  margin-right: 0.25rem;
  margin-top: 0.25rem;
}

.orange {
  background: rgba(255, 187, 55, 0.2);
  border: 0.05rem solid #ffbb37;
  color: #ffbb37;
}

.blue {
  background: rgba(12, 217, 255, 0.2);
  border: 0.05rem solid #1890ff;
  color: #1890ff;
}

.red {
  background: rgba(239, 74, 74, 0.2);
  border: 0.05rem solid #ef4a4a;
  color: #ef4a4a;
}
.green {
  background: rgba(110, 239, 149, 0.2);
  border: 0.05rem solid #6eef95;
  color: #6eef95;
}

.radius {
  border-radius: 0.2rem;
}


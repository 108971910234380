/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-menu-darkgrey,
.ant-menu-darkgrey .ant-menu-sub {
  color: #eee;
  background: #30363e;
}
.ant-menu-darkgrey .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-darkgrey .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu-darkgrey .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-darkgrey .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-darkgrey .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-darkgrey .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: #fff;
}
.ant-menu-darkgrey.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-darkgrey .ant-menu-inline.ant-menu-sub {
  background: #282d33;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}
.ant-menu-darkgrey.ant-menu-horizontal {
  border-bottom-color: #30363e;
}
.ant-menu-darkgrey.ant-menu-horizontal > .ant-menu-item,
.ant-menu-darkgrey.ant-menu-horizontal > .ant-menu-submenu {
  border-color: #30363e;
  border-bottom: 0;
}
.ant-menu-darkgrey.ant-menu-horizontal > .ant-menu-item > a:before {
  bottom: 0;
}
.ant-menu-darkgrey .ant-menu-item,
.ant-menu-darkgrey .ant-menu-item-group-title,
.ant-menu-darkgrey .ant-menu-item > a {
  color: #eee;
}
.ant-menu-darkgrey.ant-menu-inline,
.ant-menu-darkgrey.ant-menu-vertical,
.ant-menu-darkgrey.ant-menu-vertical-left,
.ant-menu-darkgrey.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-darkgrey.ant-menu-inline .ant-menu-item,
.ant-menu-darkgrey.ant-menu-vertical .ant-menu-item,
.ant-menu-darkgrey.ant-menu-vertical-left .ant-menu-item,
.ant-menu-darkgrey.ant-menu-vertical-right .ant-menu-item {
  border-right: 0;
  margin-left: 0;
  left: 0;
}
.ant-menu-darkgrey.ant-menu-inline .ant-menu-item:after,
.ant-menu-darkgrey.ant-menu-vertical .ant-menu-item:after,
.ant-menu-darkgrey.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-darkgrey.ant-menu-vertical-right .ant-menu-item:after {
  border-right: 0;
}
.ant-menu-darkgrey.ant-menu-inline .ant-menu-item,
.ant-menu-darkgrey.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-darkgrey .ant-menu-item:hover,
.ant-menu-darkgrey .ant-menu-item-active,
.ant-menu-darkgrey .ant-menu-submenu-active,
.ant-menu-darkgrey .ant-menu-submenu-open,
.ant-menu-darkgrey .ant-menu-submenu-selected,
.ant-menu-darkgrey .ant-menu-submenu-title:hover {
  background-color: transparent;
  color: #fff;
}
.ant-menu-darkgrey .ant-menu-item:hover > a,
.ant-menu-darkgrey .ant-menu-item-active > a,
.ant-menu-darkgrey .ant-menu-submenu-active > a,
.ant-menu-darkgrey .ant-menu-submenu-open > a,
.ant-menu-darkgrey .ant-menu-submenu-selected > a,
.ant-menu-darkgrey .ant-menu-submenu-title:hover > a {
  color: #fff;
}
.ant-menu-darkgrey .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-darkgrey .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-darkgrey .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-darkgrey .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-darkgrey .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-darkgrey .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-darkgrey .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-darkgrey .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-darkgrey .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-darkgrey .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-darkgrey .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-darkgrey .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-darkgrey .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-darkgrey .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-darkgrey .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-darkgrey .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-darkgrey .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-darkgrey .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-darkgrey .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-darkgrey .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-darkgrey .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-darkgrey .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-darkgrey .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-darkgrey .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-darkgrey .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-darkgrey .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-darkgrey .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-darkgrey .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-darkgrey .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-darkgrey .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-darkgrey .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-darkgrey .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-darkgrey .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-darkgrey .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-darkgrey .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-darkgrey .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before {
  background: #fff;
}
.ant-menu-darkgrey .ant-menu-item-selected {
  border-right: 0;
  color: #fff;
}
.ant-menu-darkgrey .ant-menu-item-selected:after {
  border-right: 0;
}
.ant-menu-darkgrey .ant-menu-item-selected > a,
.ant-menu-darkgrey .ant-menu-item-selected > a:hover {
  color: #fff;
}
.ant-menu.ant-menu-darkgrey .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-darkgrey .ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 4px 0px 0px 0px #1890ff inset;
}
.ant-menu-darkgrey .ant-menu-item-disabled,
.ant-menu-darkgrey .ant-menu-submenu-disabled,
.ant-menu-darkgrey .ant-menu-item-disabled > a,
.ant-menu-darkgrey .ant-menu-submenu-disabled > a {
  opacity: 0.8;
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-darkgrey .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-darkgrey .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-darkgrey .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-darkgrey .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-darkgrey .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-darkgrey .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.sidebar-left {
  background-color: #001529;
  user-select: none;
  position: relative;
  z-index: 2;
}
.sidebar-left.sidebar-left-sm .ant-menu.ant-menu-inline-collapsed {
  width: 60px;
}
.sidebar-left.sidebar-left-sm .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu .ant-menu-submenu-title,
.sidebar-left.sidebar-left-sm .ant-menu.ant-menu-inline-collapsed .ant-menu-item {
  padding: 0 20px !important;
  margin: 0;
}
.sidebar-left.sidebar-left-sm .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu .ant-menu-submenu-title .anticon,
.sidebar-left.sidebar-left-sm .ant-menu.ant-menu-inline-collapsed .ant-menu-item .anticon {
  font-size: 18px;
}
.sidebar-left.sidebar-left-sm.sidebar-left-close .ant-menu {
  transform: translate3d(-2000px, 0, 0);
}
.sidebar-left .ant-menu > .ant-menu-item-selected {
  background-color: #EC6700;
}
.sidebar-left .ant-menu > .ant-menu-item,
.sidebar-left .ant-menu > .ant-menu-submenu > .ant-menu-submenu-title {
  margin: 0;
  height: 48px;
  line-height: 48px;
  width: 100%;
}
.sidebar-left .sidebar-toggle-mini {
  text-align: center;
}
.sidebar-left.show-header .sidebar-header {
  height: 91px;
}
.sidebar-left .sidebar-header {
  transition: height 0.2s ease;
  height: 0px;
  overflow: hidden;
}
.sidebar-left .sidebar-header .userlogged {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: block;
  padding: 30px 10px 0 10px;
}
.sidebar-left .sidebar-header .userlogged > i {
  color: #5b5b5b;
  float: left;
  font-size: 60px;
  padding-right: 5px;
}
.sidebar-left .sidebar-header .userlogged .user-details {
  float: left;
  padding-left: 5px;
  text-align: left;
}
.sidebar-left .sidebar-header .userlogged .user-details > span {
  font-size: 18px;
  margin-bottom: 3px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 130px;
  display: block;
}
.sidebar-left.darkgrey .sidebar-header .userlogged .user-details > span,
.sidebar-left.dark .sidebar-header .userlogged .user-details > span {
  color: #e2e2e2;
}
.sidebar-left.darkgrey .sidebar-header .ant-select-selection,
.sidebar-left.dark .sidebar-header .ant-select-selection {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #616574;
  font-size: 12px;
}
.sidebar-left.darkgrey .sidebar-header .ant-select-selection .ant-select-arrow,
.sidebar-left.dark .sidebar-header .ant-select-selection .ant-select-arrow {
  color: #616574;
}
.sidebar-left.darkgrey {
  background-color: #30363e;
}
.sidebar-left.darkgrey .ant-menu {
  border-right: 0;
  background: #30363e;
}
.sidebar-left.darkgrey .ant-menu.ant-menu-sub {
  background-color: #282d33;
}
.sidebar-left.light {
  background-color: #fff;
}
.sidebar-left.light .ant-menu {
  border-right: 0;
  background: #fff;
}
.sidebar-left.light .ant-menu.ant-menu-sub {
  background-color: #fafafa;
}
.sidebar-left.grey {
  background-color: #fafafa;
}
.sidebar-left.grey .ant-menu {
  border-right: 0;
  background: #fafafa;
}
.sidebar-left.grey .ant-menu.ant-menu-sub {
  background-color: #f4f4f4;
}
.grey,
.light {
  border-right: 1px solid #e8e8e8;
}
.grey .ant-menu-root.ant-menu,
.light .ant-menu-root.ant-menu {
  position: relative;
}
.sidebar-header-dropdown {
  font-size: 13px;
}
.ant-select-selection-selected-value .user,
.ant-select-dropdown-menu-item .user {
  border-radius: 50%;
  content: "";
  float: left;
  height: 7px;
  margin-right: 8px;
  margin-top: 7px;
  width: 7px;
}
.ant-select-selection-selected-value .online,
.ant-select-dropdown-menu-item .online {
  background-color: #18a689;
}
.ant-select-selection-selected-value .invisible,
.ant-select-dropdown-menu-item .invisible {
  background-color: #ff9900;
}
.ant-select-selection-selected-value .busy,
.ant-select-dropdown-menu-item .busy {
  background-color: #c75757;
}
.ant-select-selection-selected-value .offline,
.ant-select-dropdown-menu-item .offline {
  background-color: #666;
}
.left-sidebar-drawer .navbar-branding {
  position: relative;
  overflow: hidden;
  height: 60px;
  background-color: transparent;
}
.left-sidebar-drawer .navbar-branding .navbar-brand {
  color: #666;
  height: 60px;
  line-height: 58px;
  padding: 0;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.6px;
  padding-left: 15px;
  display: inline-block;
  overflow: hidden;
  width: 170px;
}
.left-sidebar-drawer .navbar-branding .navbar-brand b {
  font-weight: bold;
  padding-right: 1px;
}
.left-sidebar-drawer .navbar-branding .navbar-brand img {
  width: 35px;
  height: auto;
  margin-right: 10px;
}
.left-sidebar-drawer .navbar-branding .toggle_sidemenu_l {
  float: right;
  cursor: pointer;
  font-size: 15px;
  line-height: 58px;
  max-height: 60px;
  width: 45px;
  text-align: center;
  -webkit-touch-callout: none;
  user-select: none;
}
.left-sidebar-drawer .ant-drawer-close-x {
  display: none;
}
.left-sidebar-drawer .ant-drawer-body {
  padding: 0px;
  overflow-y: auto;
  overflow-x: hidden;
}
.left-sidebar-drawer .sidebar-left {
  min-height: 100vh;
}
.sidebar-right {
  user-select: none;
}
.sidebar-right.ant-layout-sider {
  background: #f8f8f8;
  border-left: 1px solid #ddd;
}

@import '~styles/common-styles.less';
@import '~antd/lib/checkbox/style/index.less';

.checkbox {
    font-size: 14px !important;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFF !important;
    line-height: 1.3 !important;

    &>span {
        display: inline-flex;
    }



    :global {
        .ant-checkbox-indeterminate {
            & .ant-checkbox-inner {
                background: rgba(0, 0, 0, 0.45) !important;
            }
        }

        .ant-checkbox+span {
            padding-right: 8px;
            padding-left: 8px;
        }

        .ant-checkbox-inner {
            width: 16px;
            height: 16px;
            border-radius: 2px;
            background: rgba(0, 0, 0, 0.45);
            border: 0.05rem solid rgba(255, 255, 255, 0.45);


            &::after {
                width: 6px;
                height: 9px;
                border-bottom: 2px solid #0CD9FF;
                border-right: 2px solid #0CD9FF;
            }
        }

        .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus+.ant-checkbox-inner {
            border: 0.05rem solid #0CD9FF;

        }

        .ant-checkbox-input,
        .ant-checkbox-wrapper,
        .ant-checkbox {
            &:focus {
                &+.ant-checkbox-inner {
                    border: 0.05rem solid #0CD9FF;
                }
            }

            &:hover {
                & .ant-checkbox-inner {
                    border: 0.05rem solid #0CD9FF;
                }
            }
        }

        .ant-checkbox-checked {
            .ant-checkbox-inner {
                background: rgba(0, 0, 0, 0.45);
                border: 0.05rem solid #0CD9FF;

                &::after {
                    width: 6px;
                    height: 9px;
                    border-bottom: 2px solid #0CD9FF;
                    border-right: 2px solid #0CD9FF;
                }
            }

            &::after {
                border: 0.05rem solid #0CD9FF;
                border-radius: 2px;
            }
        }

        .ant-checkbox-indeterminate {
            & .ant-checkbox-inner {
                border: 0.05rem solid #0CD9FF;

                &::after {
                    width: 8px;
                    height: 8px;
                    background-color: #0CD9FF;
                }
            }
        }
    }
}
.main {
  width: 100%;
  height: 400px;
  display: flex;
  & .image {
    width: 1px;
    height: 100%;
    flex-grow: 1;
    object-fit: contain;
  }
  & .alarm_div {
    width: 230px;
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    & .title {
      font-size: 18px;
      font-weight: bold;
    }
    & .alarm_info,
    & .device_info {
      width: 100%;
      display: flex;
      flex-direction: column;
      & .row {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        & .name {
          color: rgba(0, 0, 0, 0.6);
        }
        & .tag {
          margin: 0;
          padding: 4px 16px;
        }
      }
    }
    & .device_info {
      background: rgba(240, 240, 240, 1);
      margin-top: 16px;
      padding: 16px 8px;
      & .row {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}


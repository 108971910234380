.antui-editor {
  .w-e-toolbar {
    flex-wrap: wrap;
    .w-e-menu {
      &:hover {
        z-index: 10002 !important;
      }
    }
  }
}
